.streaming-card-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
}

.streaming-card {
    max-width: 115px;
    width: 100%;
    margin: 0 auto 20px;
}

.streaming-card .card-img {
    position: relative;
    max-width: 115px;
    width: 100%;
    border-radius: 5px;
}

.streaming-card .card-img .blurImg-sec {
    box-shadow: inset 30px 30px 50px #000000CC;
    opacity: 1;
    position: relative;
}

.streaming-card .card-img .blurImg-sec:before {
    content: "";
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.streaming-card .card-img .profile-img {
    position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
}

.streaming-card .card-img .profile-img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.streaming-card .card-content .user-name {
    font-size: 14px;
    line-height: 18px;
    font-family: 'Century-Gothic';
    word-break: break-all;
    margin: 5px 0 10px;
}

.streaming-card .card-content .user-hrs {
    font-size: 12px;
    line-height: 16px;
}

.addstreaming {
    padding: 50px 0 30px;
}

input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
}

.streaming-card .blurImg-sec>img {
    max-width: 100%;
}

.streaming-card .profile-img {
    padding: 0;
}

.streaming-card .profile-img img {
    object-position: top;
}

.streaming-card .blurImg-sec>img {
    max-width: 100%;
    height: 100%;
    min-height: 250px;
    object-fit: cover;
}