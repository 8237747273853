.teampofile-sec {
    margin-bottom: 40px;
}

.radio-btns-sec {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.right-radios-sec {
    position: relative;
}

.right-radios-sec .check-box {
    display: flex;
    align-items: center;
}

.right-radios-sec .check-box span {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Century-Bold';
}

.right-radios-sec input {
    margin: 0 15px;
}

.right-radios-sec input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 52px;
    height: 29px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
    background: #CB8B15;
}

.right-radios-sec input:checked[type="checkbox"] {
    background: #CB8B15;
}

.right-radios-sec input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    top: 2px;
    left: 1px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
}

.right-radios-sec input:checked[type="checkbox"]::after {
    left: 50%;
}

.left-radios-sec {
    display: flex;
    align-items: center;
}

.left-radios-sec .radio-sec {
    margin-right: 20px;
    border: unset;
}

.left-radios-sec .radio-sec input {
    margin-right: 10px;
}

.left-radios-sec .radio-sec label {
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-family: 'Century-Bold';
    margin: 0;
}

.teamprofile-card {
    border-radius: 5px;
    background: #1F1F1F;
    max-width: 130px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
}

.teamprofile-card .card-header {
    padding: 15px;
    height: 150px;
    text-overflow: ellipsis;
}

.teamprofile-card .card-profile-img {
    position: relative;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
}

.user-admin-profile-img {
    position: relative;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    width: 130px;
    height: 130px;
}

.user-admin-profile-img::before {
    content: "";
    position: absolute;
    background: url('../../../../public/img/background_leaf_icon.svg');
    /* background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA9CAYAAAD7/KSFAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAQkSURBVHhe7Vv9VdswENeZlveSlIQNGiZoMkFhgsIEpRMUJiidoHSC0glKJyidADoBYQMSmuQ9WqKenYSHZck+6STHUOfPWLo7/3RfujuDYP6klJtyMroUAJtMUvztUl5Ds70FANccYsDZHO+9G49OAMRbLh1f+6UUX9da7X0OPRYo8vZ3T/6dnXMECLEXnkV9WH9x4UqbBcpsMvqBjLddmQfcdxY12zuu9J1BkdObbfQnMSiV/KFf2YHGxpmLcM6gzCbDSyGgm2Yqr6JmR/nPRSy7PSjLAGV5qcgyQFm27CjNVzuBIifDAyngk8oQpNiDVvvURRDOHjke7UoQ3zLyCHkIzc6xLW1rUHJC8E+045X5F/Rvsam8TgHgGKKtQbmb3ByDkO8zp8L0+Lanqa43RULU6M9rzY0DG/pWoMjptCvlH/QlivV6yA1shDatNeVMAM+3oNFAv0P7WYGCKhrb7a6iokPMIrvcLJImbv6q5NBmtxeYXXeUlado2ntUHmRQTCEYfczHtVbniMow9Lq78fAID+hDxrwtQjQZlNl4eI4n0FMM5woa7V4VtGQpVxIIpiPMZpUQLeVF1Or0KYdCAgWZ7OOd4ksWffEOQTmhMCpzDVfeQlCqGoKLQOaE6EJQfNho0QuEeM7xgbmgLLx57EtStRIf1/MQQKg0UVvi7PqNEi2vIVrv54XoXFB8xf0yANDxcM2rjKBw1G9VIOj4upi/ERRtrUTKyiRqVOAXgWKgSeiMNRctKNyQRhW4rHW275MBZZH8YIkRumkHJX5FrbaSvJX1Wnw+szEmdCBeKcmntuaSAcXFBvkih6dg4yNToDzWRI0KKTWhS4Hy2ENwETjUEH0Pis8iTZFwq3xOKZLdg/JUQnAR4JQQnYDiu/BbJNiqnxsL74uaSwJKldoVZQGW1xaBqrUrygIl1zp8N5LKeikffEwWUmuKgi62bw6XPmXgs+3o4xRD08jzo/PoY2iWJ6g5tB1DvxCXftFV5mGecobMvLQduUKH3E+5ytQZ7eIEHg761Hef2H0obV/dLdmqShVS1X3Tpl5l6nqKpu2rLUdqEzqLtqPvE/ZBz+YqU9doNW3fvGr+kwjRlBCsamLd99HYZt0htAXFNBn0iHrJ+smraL3n3EuOQSy6J/iIDCFo2LQ0yD5luZBS0wzxUlya1ERNx6dwPmV+i64nmbSHZGo74nhXv4Izb6y2L0lT8mou//V0ZAyMcTLIw9dYXB8yP7hkjjYzeYWPvuMcbXr+N4chWVPumdYT11k4KW1HH6duS8PY9nUYfrbSlERb5h9SVq7mwgnB1nmK7sSq1kDz3fa11pQlSFVqotnUSihm6QxK/Q2hAV7tZBDlKMKvYX2l5qwpidOt6nfJlh89eXG0D4nUX7Br1D4nRIc3EpWDp+HnfwjjTNxb4S97AAAAAElFTkSuQmCC); */
    background-repeat: no-repeat;
    z-index: 9;
    width: 125px;
    height: 113px;
    background-size: contain;
    background-position: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
}

.user-admin-profile-img img {
    width: 105px;
    height: 105px;
    object-fit: cover;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    border-radius: 50%;
    z-index: 999;
    position: relative;
}

.teamprofile-card .card-profile-img::before {
    content: "";
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA9CAYAAAD7/KSFAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAQkSURBVHhe7Vv9VdswENeZlveSlIQNGiZoMkFhgsIEpRMUJiidoHSC0glKJyidADoBYQMSmuQ9WqKenYSHZck+6STHUOfPWLo7/3RfujuDYP6klJtyMroUAJtMUvztUl5Ds70FANccYsDZHO+9G49OAMRbLh1f+6UUX9da7X0OPRYo8vZ3T/6dnXMECLEXnkV9WH9x4UqbBcpsMvqBjLddmQfcdxY12zuu9J1BkdObbfQnMSiV/KFf2YHGxpmLcM6gzCbDSyGgm2Yqr6JmR/nPRSy7PSjLAGV5qcgyQFm27CjNVzuBIifDAyngk8oQpNiDVvvURRDOHjke7UoQ3zLyCHkIzc6xLW1rUHJC8E+045X5F/Rvsam8TgHgGKKtQbmb3ByDkO8zp8L0+Lanqa43RULU6M9rzY0DG/pWoMjptCvlH/QlivV6yA1shDatNeVMAM+3oNFAv0P7WYGCKhrb7a6iokPMIrvcLJImbv6q5NBmtxeYXXeUlado2ntUHmRQTCEYfczHtVbniMow9Lq78fAID+hDxrwtQjQZlNl4eI4n0FMM5woa7V4VtGQpVxIIpiPMZpUQLeVF1Or0KYdCAgWZ7OOd4ksWffEOQTmhMCpzDVfeQlCqGoKLQOaE6EJQfNho0QuEeM7xgbmgLLx57EtStRIf1/MQQKg0UVvi7PqNEi2vIVrv54XoXFB8xf0yANDxcM2rjKBw1G9VIOj4upi/ERRtrUTKyiRqVOAXgWKgSeiMNRctKNyQRhW4rHW275MBZZH8YIkRumkHJX5FrbaSvJX1Wnw+szEmdCBeKcmntuaSAcXFBvkih6dg4yNToDzWRI0KKTWhS4Hy2ENwETjUEH0Pis8iTZFwq3xOKZLdg/JUQnAR4JQQnYDiu/BbJNiqnxsL74uaSwJKldoVZQGW1xaBqrUrygIl1zp8N5LKeikffEwWUmuKgi62bw6XPmXgs+3o4xRD08jzo/PoY2iWJ6g5tB1DvxCXftFV5mGecobMvLQduUKH3E+5ytQZ7eIEHg761Hef2H0obV/dLdmqShVS1X3Tpl5l6nqKpu2rLUdqEzqLtqPvE/ZBz+YqU9doNW3fvGr+kwjRlBCsamLd99HYZt0htAXFNBn0iHrJ+smraL3n3EuOQSy6J/iIDCFo2LQ0yD5luZBS0wzxUlya1ERNx6dwPmV+i64nmbSHZGo74nhXv4Izb6y2L0lT8mou//V0ZAyMcTLIw9dYXB8yP7hkjjYzeYWPvuMcbXr+N4chWVPumdYT11k4KW1HH6duS8PY9nUYfrbSlERb5h9SVq7mwgnB1nmK7sSq1kDz3fa11pQlSFVqotnUSihm6QxK/Q2hAV7tZBDlKMKvYX2l5qwpidOt6nfJlh89eXG0D4nUX7Br1D4nRIc3EpWDp+HnfwjjTNxb4S97AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    z-index: -1;
    width: 90px;
    height: 80px;
    background-size: contain;
    background-position: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    overflow: hidden;
}

.teamprofile-card .card-profile-img img {
    width: 80px;
    height: 70px;
    object-fit: cover;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.teamprofile-card .card-header .team-name {
    font-size: 13px;
    text-align: center;
    padding: 15px 0 5px;
    font-family: 'Century-Bold';
    margin: 0;
}

.teamprofile-card .card-bottom {
    background-color: #3A3A3A;
    padding: 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.teamprofile-card .card-bottom .red-rank {
    font-size: 13px;
    text-align: center;
    font-family: 'Century-Bold';
    color: #FF0707;
}

.teamprofile-card .card-bottom .team {
    font-size: 13px;
    text-align: center;
    font-family: 'Century-Bold';
}

.teamprofile-card .card-bottom .team img {
    width: 20px;
    height: 20px;
}

.teampofile-sec h4 {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Century-Bold';
    margin-bottom: 20px;
}

.forwords-sec h4 {
    color: #FF0707;
}

.forwords-col h4 {
    color: #1DBE2E;
}

.midfielders-sec h4 {
    color: #005EAA;
}

.defensores-sec h4 {
    color: #cb8b14;
}

.technical-staff .card-bottom {
    justify-content: center;
}

.juveniles-card {
    max-width: 285px;
    width: 100%;
    margin: 0 auto 30px;
}

.juveniles-card .card-img-sec img,
.juveniles-card .card-img-sec video {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
}

.team-container,
.trophy-tab-sec,
.equipment-tab-sec,
.profile-sec-tabs,
.feild-position-sec {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
}

.juveniles-sec-container {

    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
}

.juveniles-card .card-heading p {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;
}

.personal-info-sec {
    margin-bottom: 45px;
}

.personal-info-sec .personal-info-heading {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 20px;
    font-family: 'Century-Italic';
}

.personal-info-card,
.update-input-field {
    background-color: #fff;
    /* padding: 10px 20px; */
    padding: 0px 20px;
    border-radius: 50px;
    color: #131313;
    margin-bottom: 20px;
}

.personal-info-card .info-heading {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Italic';
}

.personal-info-card .info-data {
    margin: 0;
    font-size: 19px;
    line-height: 24px;
    font-family: 'Century-BoldItalic';
    text-align: center;
}

.profile-btsn-inner {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.profile-btns-sec {
    position: relative;
    height: 100%;
}

.profile-btsn-inner .green-blue-btn {
    margin-bottom: 20px;
}

.feild-sec .feild-sec-heading {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Century-Italic';
    margin-bottom: 20px;
}

.feild-sec .feild-card {
    background-color: #fff;
    display: flex;
    border-radius: 50px;
    padding: 8px 15px;
    color: #131313;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.feild-sec .feild-card p {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    font-family: 'Century-Italic';
}

.feild-sec .feild-card h5 {
    margin: 0;
    font-size: 19px;
    line-height: 20px;
    font-family: 'Century-BoldItalic';
}

.feild-sec-info {
    padding: 60px 0 10px;
}

.feild-sec-info .green-text {
    color: #25762E;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
    text-transform: capitalize;
}

.feild-sec-info .per-sec {
    display: flex;
    justify-content: space-between;
}

.feild-sec-info .per-sec {
    font-size: 16px;
    line-height: 20px;
}

.feild-sec-img {
    max-width: 150px;
    height: 250px;
    width: 100%;
    margin: 20px auto 0;
}

.feild-sec-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.attribute-img-sec {
    margin: 30px auto 0;
}

.equipment-card-sec .equipment-card {
    background-color: #fff;
    padding: 8px 15px;
    margin-bottom: 15px;
    color: #131313;
    text-align: center;
    border-radius: 50px;
}

.equipment-card-sec .equipment-card h5 {
    margin: 0;
    font-size: 19px;
    line-height: 20px;
    font-family: 'Century-BoldItalic';
}

.equipment-list-sec .equipment-list {
    background-color: #131313;
    border-radius: 5px;
    padding: 30px;
    max-width: 690px;
    width: 100%;
    margin: 30px auto 0;
}

.equipment-list-sec .equipment-list .equp-list-content {
    position: relative;
    display: block;
    margin-bottom: 30px;

}

.equipment-list .equp-list-content .equ-list-desc {
    display: inline-block;
    padding-left: 60px;
    font-size: 12px;
    line-height: 19px;
    font-family: 'Century-BoldItalic';
}

.equipment-list .equp-list-content img {
    position: absolute;
    width: 50px;
    height: 50px;
    filter: drop-shadow(0 0 0.75rem crimson);
    border-radius: 50%;
    object-fit: contain;
}

.trophy-sec-card .trophy-card {
    background-color: #fff;
    padding: 0px 50px;
    margin-bottom: 5px;
    color: #131313;
    text-align: center;
    border-radius: 50px;
}

.trophy-sec-card .trophy-card h5 {
    margin: 0;
    font-size: 19px;
    line-height: 20px;
    font-family: 'Century-BoldItalic';
}

.trophy-profile-card {
    max-width: 400px;
    width: 100%;
    display: flex;
    margin: 30px 0 20px;
}

.trophy-profile-card .trophy-img {
    width: 57px;
    height: 57px;
    margin-right: 20px;
}

.trophy-profile-card .trophy-details-sec h4 {
    font-size: 19px;
    line-height: 25px;
    font-family: 'Century-Gothic';
    margin-bottom: 15px;
}

.trophy-profile-card .trophy-details-sec p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Gothic';
}

.radio-sec [type="radio"]:checked,
.radio-sec [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.radio-sec [type="radio"]:checked+label,
.radio-sec [type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}

.radio-sec [type="radio"]:checked+label:before,
.radio-sec [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #005EAA;
    border-radius: 100%;
    background: transparent;

}

.radio-sec [type="radio"]:checked+label:after,
.radio-sec [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #005EAA;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.radio-sec [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.radio-sec [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.categories-dropdown .dropdown-btn {
    background: #131313;
    padding: 10px 15px;
    border-radius: 50px;
    border: 1px solid #FEF6F2;
    font-size: 13px;
    line-height: 18px;
    font-family: 'Century-Bold';
    color: #CB8B15;
    /* width: 130px; */
}

.categories-dropdown .dropdown-btn:hover {
    background: #131313;
    color: #CB8B15;
    border: 1px solid #FEF6F2;
}

.categories-dropdown .dropdown-btn.btn-check:checked+.btn,
:not(.btn-check)+.dropdown-btn.btn:active,
.dropdown-btn.btn:first-child:active,
.dropdown-btn.btn.active,
.dropdown-btn.btn.show {
    background: #131313;
    color: #CB8B15;
    border: 1px solid #FEF6F2;
}

.categories-dropdown .dropdown-menu {
    border: 1px solid #303030;
    width: 130px;
    min-width: 130px;
    text-align: center;
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    background: transparent;
    overflow: auto;
    height: 250px;
}

.categories-dropdown .dropdown-menu a {
    font-size: 16px;
    line-height: 25px;
    font-family: 'Century-Italic';
    color: #ffffff;
    padding: 10px;
    text-align: left;
}

.categories-dropdown .dropdown-menu .dropdown-item.active,
.categories-dropdown .dropdown-menu .dropdown-item:active {
    background: transparent;
}

.categories-dropdown .dropdown-menu a:hover {
    background: transparent;
    color: #CB8B15;
}

.admin-menu-sec {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.admin-menu-sec .menu-dropdown {
    background: #131313;
    color: #fff;
    text-align: center;
    width: 155px;
    padding: 10px 15px;
    border-radius: 0;
    border: 1px solid #FEF6F2;
    font-family: 'Century-Bold';
}

.admin-menu-sec .menu-dropdown:hover {
    background: #131313;
    border: 1px solid #FEF6F2;
}

.admin-menu-sec .dropdown-toggle::after {
    display: none;
}

.admin-menu-sec .menu-dropdown.btn-check:checked+.btn,
:not(.btn-check)+.menu-dropdown.btn:active,
.menu-dropdown.btn:first-child:active,
.menu-dropdown.btn.active,
.menu-dropdown.btn.show {
    background: #131313;
    border: 1px solid #FEF6F2;
}

.admin-menu-sec .dropdown-menu {
    background: #131313;
    text-align: center;
    width: 155px;
    min-width: 100%;
    border: 1px solid #FEF6F2;
    padding: 0;
    border-radius: 0;
}

.admin-menu-sec .dropdown-menu a {
    padding: 15px 15px;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
    font-family: 'Century-Gothic';
}

.admin-menu-sec .dropdown-menu a:hover {
    background-color: #CB8B15;
}

.selection-dropdown.categories-dropdown .dropdown-menu a {
    font-size: 13px;
}

.user-admin-profile {
    max-width: 160px;
    margin: 0 auto;
    text-align: center;
}

.profile-desc-sec .profile-row-sec {
    align-items: center;
    padding: 30px 0;
}

.parties-dropdown .menu-dropdown {
    background: #131313;
    border: 0.3px solid #FEF6F2;
    padding: 10px 20px;
    border-radius: 50px;
    max-width: initial;
    width: 100%;
    text-align: left;
    font-size: 13px;
    color: #CB8B15;
    word-break: break-all;
    font-family: 'Century-Italic';
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* .parties-dropdown .menu-dropdown {
    background: #131313;
    border: 0.3px solid #FEF6F2;
    padding: 10px 20px;
    border-radius: 50px;
    max-width: 230px;
    width: 100%;
    text-align: left;
    font-size: 13px;
    color: #CB8B15;
    font-family: 'Century-Italic';
} */

.parties-dropdown .menu-dropdown:hover {
    background-color: transparent;
    border: 1px solid #FEF6F2;
}

.parties-dropdown .menu-dropdown btn-check:checked+.btn,
:not(.btn-check)+.parties-dropdown .menu-dropdown.btn:active,
.parties-dropdown .menu-dropdown.btn:first-child:active,
.btn.active,
.parties-dropdown .menu-dropdown.btn.show {
    color: #CB8B15;
}

.parties-dropdown .dropdown-menu {
    background-color: #131313;
    border: 0.3px solid #FEF6F2;
    min-width: 230px;
    max-width: 230px;
    width: 100%;
    padding: 0;

}

.parties-dropdown .dropdown-menu a.dropdown-item {
    font-family: 'Century-Italic';
    font-size: 13px;
    color: #fff;
    padding: 20px 20px;
}

.parties-dropdown .dropdown-menu a.dropdown-item:hover {
    background-color: transparent;
    color: #CB8B15;
}

.teampoints-sec {
    max-width: 390px;
    width: 100%;
    background-color: #0D0D0D;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.matches-history-card {
    padding: 10px 8px;
    border: 1px solid #505050;
}

.matches-history-card .card-heading {
    font-size: 14px;
    margin: 0 0 10px 0;
    text-align: center;
}

.team-points-list {
    padding: 0;
    margin: 0;
}

.team-points-list .matches-history-card .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-points-list .matches-history-card .card-body .team-name {
    font-size: 14px;
    margin: 0;
}

.matches-history-card .left-team,
.matches-history-card .right-team {
    display: flex;
    align-items: center;
}

.matches-history-card .left-team img,
.matches-history-card .right-team img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 50%;
}

.matches-history-card .score-sec {
    margin: 0 15px;
}

.matches-history-card .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0;
}

.matches-history-card .card-footer .date {
    margin: 0;
    font-size: 14px;
}

.matches-history-card .card-footer span {
    font-size: 12px;
}

.teams-points-list-sec {
    border: 1px solid #505050;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 5px;
}

.teams-points-list-sec .list-heading {
    padding: 10px 20px;
    font-size: 16px;
    font-family: 'Century-Bold';
    margin: 0;
}

/* --edit player profile-pic-- */
.editnewscard-img {
    max-width: 350px;
    height: 200px;
    margin: 0px auto 0;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
}

/* .editnewscard-img {
    max-width: 350px;
    height: 110px;
    margin: 0px auto 0;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
} */

.img-edit-icon input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: black;
    z-index: 999;
}

.img-edit-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    right: 10px;
    bottom: 10px;
    color: black;
    z-index: 999;
}

.user-admin-profile {
    max-width: 225px;
    margin: 0 auto;
    text-align: center;
}

.form-control {
    max-width: 890px;
    width: 100%;
    background-color: #fff;
    border-radius: 39px;
    text-align: center;
    padding: 0px;
    margin-bottom: 2px;
    color: #131313;
    border: none;
}

.form-control label {
    font-size: 19px;
    line-height: 20px;
    font-family: 'Century-BoldItalic';
    display: block;
    margin-bottom: 5px;
}

.form-control input {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Italic';
    margin: 0;
    /* max-width: 400px; */
    width: 100%;
    border: 0;
    padding: 5px 10px;
    outline: 0;
    text-align: center;
}

.form-control input::placeholder {
    text-align: center;
}

.form-control select {
    width: 100%;
    border: 0;
    font-size: 16px;
    color: #5e5959;
    outline: none;
    text-align: center;
}

.personal-info-card .info-heading {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Italic';
    width: 175px;
    margin-top: 10px;
}

.juveniles-sec-container button.green-btn {
    max-width: 230px;
    margin: 30px auto 15px;
}

.teampofile-outer {
    height: 500px;
    height: 500px;
    padding: 0 20px;
    overflow: auto;
}

.teampofile-outer .center-space-btn {
    position: sticky;
    bottom: 0;
    background: #070707;
    padding: 10px 0;
}

.teampofile-outer .center-space-btn button {
    margin: 0;
}

.partidos-inner .matches-history-card .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partidos-team-list {
    margin-top: 35px;
}

.feild-team-score img {
    height: 40px;
    width: 35px;
}

.feild-team-score {
    padding: 10px 15px !important;
}

.partidos-team-list .feild-card input {
    border: unset;
    font-size: 19px;
    line-height: 20px;
    text-align: right;
    font-family: 'Century-BoldItalic';
}

.partidos-inner .matches-history-card {
    background: #131313;
    position: relative;
}

.prox-box {
    display: inline-block;
    border: 1px solid #707070;
    font-size: 11px;
    padding: 2px 7px;
    background: #000;
    position: absolute;
    top: 10px;
    left: 10px;
}

.scrollbar-plantel {
    overflow: inherit;
}

.user-enc-img .editnewscard-img {
    width: 114px;
    height: 104px;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    margin: 0;
    z-index: -1;
    position: static;
}

.user-enc-img .editnewscard-img>img {
    object-fit: cover;
    z-index: -1;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.user-enc-img .user-admin-profile-img::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA9CAYAAAD7/KSFAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAQkSURBVHhe7Vv9VdswENeZlveSlIQNGiZoMkFhgsIEpRMUJiidoHSC0glKJyidADoBYQMSmuQ9WqKenYSHZck+6STHUOfPWLo7/3RfujuDYP6klJtyMroUAJtMUvztUl5Ds70FANccYsDZHO+9G49OAMRbLh1f+6UUX9da7X0OPRYo8vZ3T/6dnXMECLEXnkV9WH9x4UqbBcpsMvqBjLddmQfcdxY12zuu9J1BkdObbfQnMSiV/KFf2YHGxpmLcM6gzCbDSyGgm2Yqr6JmR/nPRSy7PSjLAGV5qcgyQFm27CjNVzuBIifDAyngk8oQpNiDVvvURRDOHjke7UoQ3zLyCHkIzc6xLW1rUHJC8E+045X5F/Rvsam8TgHgGKKtQbmb3ByDkO8zp8L0+Lanqa43RULU6M9rzY0DG/pWoMjptCvlH/QlivV6yA1shDatNeVMAM+3oNFAv0P7WYGCKhrb7a6iokPMIrvcLJImbv6q5NBmtxeYXXeUlado2ntUHmRQTCEYfczHtVbniMow9Lq78fAID+hDxrwtQjQZlNl4eI4n0FMM5woa7V4VtGQpVxIIpiPMZpUQLeVF1Or0KYdCAgWZ7OOd4ksWffEOQTmhMCpzDVfeQlCqGoKLQOaE6EJQfNho0QuEeM7xgbmgLLx57EtStRIf1/MQQKg0UVvi7PqNEi2vIVrv54XoXFB8xf0yANDxcM2rjKBw1G9VIOj4upi/ERRtrUTKyiRqVOAXgWKgSeiMNRctKNyQRhW4rHW275MBZZH8YIkRumkHJX5FrbaSvJX1Wnw+szEmdCBeKcmntuaSAcXFBvkih6dg4yNToDzWRI0KKTWhS4Hy2ENwETjUEH0Pis8iTZFwq3xOKZLdg/JUQnAR4JQQnYDiu/BbJNiqnxsL74uaSwJKldoVZQGW1xaBqrUrygIl1zp8N5LKeikffEwWUmuKgi62bw6XPmXgs+3o4xRD08jzo/PoY2iWJ6g5tB1DvxCXftFV5mGecobMvLQduUKH3E+5ytQZ7eIEHg761Hef2H0obV/dLdmqShVS1X3Tpl5l6nqKpu2rLUdqEzqLtqPvE/ZBz+YqU9doNW3fvGr+kwjRlBCsamLd99HYZt0htAXFNBn0iHrJ+smraL3n3EuOQSy6J/iIDCFo2LQ0yD5luZBS0wzxUlya1ERNx6dwPmV+i64nmbSHZGo74nhXv4Izb6y2L0lT8mou//V0ZAyMcTLIw9dYXB8yP7hkjjYzeYWPvuMcbXr+N4chWVPumdYT11k4KW1HH6duS8PY9nUYfrbSlERb5h9SVq7mwgnB1nmK7sSq1kDz3fa11pQlSFVqotnUSihm6QxK/Q2hAV7tZBDlKMKvYX2l5qwpidOt6nfJlh89eXG0D4nUX7Br1D4nRIc3EpWDp+HnfwjjTNxb4S97AAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}


.team-forword-sec .teamprofile-card .card-profile-img::before {
    z-index: 9 !important;
}

.team-administration-page .teampoints-sec {
    max-width: 100%;
}

.team-administration-page .matches-history-card .left-team,
.team-administration-page .matches-history-card .right-team,
.team-administration-page .card-footer .stadium,
.team-administration-page .card-footer .right-sec {
    flex: 0 40%;
    gap: 5px;
}

.team-administration-page .matches-history-card .left-team {
    text-align: end;
    justify-content: end;
}

.team-administration-page .matches-history-card .score-sec,
.team-administration-page .card-footer .date-sec {
    flex: 0 1 20%;
    margin: 0;
    text-align: center;
}

.team-administration-page .team-points-list .matches-history-card .card-body {
    width: 100%;
    flex-wrap: wrap;
    text-align: center;

}

.team-administration-page .card-footer .right-sec {
    text-align: end;

}

.team-administration-page .card-body .right-team {
    text-align: start;
}

.team-administration-page .card-footer {
    padding-top: 5px;
}

.team-administration-page .matches-history-card {
    padding-bottom: 0;
}

.feild-card.feild-team-score input[type="number"] {
    width: 100%;
    height: 100%;
    max-width: 40px;
    color: #000;
    font-size: 30px;
    text-align: center;
    border: 1px solid #c2c2c2;
}

/* Chrome, Safari, Edge, Opera */
.feild-card.feild-team-score input::-webkit-outer-spin-button,
.feild-card.feild-team-score input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.feild-card.feild-team-score input[type=number] {
    appearance: auto;
    -moz-appearance: textfield;
}

.team-administration-page .feild-sec .feild-card select {
    border: 0;
    text-align: end;
    font-size: 19px;
    font-weight: 600;
    font-family: 'Century-BoldItalic';
}

.team-administration-page .teamprofile-card .card-profile-img::before {
    z-index: 9;
}

.personal-info-card .info-data span {
    font-size: 19px;
    margin: 0;
    font-size: 19px;
    line-height: 24px;
    font-family: 'Century-BoldItalic';
    text-align: center;
    margin-right: 5px;
}

.personal-info-card .form-control,
.personal-info-card input {
    border: none;
    font-size: 16px;
    margin: 0;
    height: 100%;
    font-family: 'Century-Bold';
    text-align: center;
}

.personal-info-card .row {
    align-items: center;
    text-align: center;
}

.personal-info-card .info-heading {

    margin-top: 0;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.user-admin-profile .common-form-control {
    height: 60px;
    position: relative;
}

.user-admin-profile {
    max-width: 375px;
}

.teampofile-outer {
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
}

.editnews-Content p.desc {
    font-size: 12px;
    line-height: 19px;
}

.squipos-sec .common-form-control {
    height: 35px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

.squipos-sec .common-form-control>select {
    width: calc(100% - 15px);
}

.squipos-sec .common-form-control>.form-control {
    width: calc(100% - 5px);
    border-radius: 30px;
    border: unset;
    height: 100%;
}


.squipos-sec .common-form-control input::placeholder,
.squipos-sec .common-form-control select {
    font-family: 'Century-BoldItalic';
    color: #000;
    text-transform: capitalize;
}

/* select.gender-selection {
    word-wrap: normal;
    margin-top: 5px;
    background: transparent;
    color: white;
    border-radius: 16%;
    width: 96%;
    font-size: 14px;
} */

/* select.gender-selection {
    word-wrap: normal;
    margin-top: 2px;
    background: #131313;
    color: white;
    border:"1px solid gray";
    width: 96%;
    font-size: 14px;
} */

select.gender-selection {
    word-wrap: normal;
    margin-top: 2px;
    background: #131313;
    color: white;
    border: 1px solid gray;
    width: 96%;
    font-size: 14px;
    padding: 6px 20px;
    border-radius: 50px;

}

.staff-category {
    width: 100% !important;
}

.select-clrImg .select-clr-Name {
    max-width: calc(100% - 70px);
    width: 100%;
}

.select-clr-Name input {
    /* padding: 10px; */
    min-height: 32px;
    font-size: 14px;
    border: 1px solid gray;
}

.staff-profile .profile-img {
    margin: auto;
    border-radius: 50%;
    width: 138px;
    height: 100px;
    object-fit: cover;
    object-position: top;
    margin-left: 42%;
}

.cross-icon-staff {
    background: white;
    border-radius: 50%;
    margin-left: 55%;
    /* border: solid black 2px; */
    cursor: pointer;
}





/* 
table {
    width: 750px;
    text-align: center;
    margin: 50px auto;

    color: #fff;


} */

tr td:nth-child(2) {
    text-align: center;
    width: 25px;
}

/* tr td:nth-child(3) {} */

.\31 -row p {
    margin: 0 0 5px;
}

.table-img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.cb {
    background-color: #CB8B15 !important;
}

.\31 -row {
    padding: 10px 0;
}

.extra {
    background: #FF0000 !important;
}

.extra-draw {
    background: #1B6FB7 !important;
}

.\31 -row span {
    background: #0D873F;
    border-radius: 2px;
    padding: 4px;
    margin: 0 2px;
}

table,
th,
td {
    border-collapse: collapse;

}

.table_left {
    text-align: right;
    padding-right: 20px;
}

.custom-admin-tabs {
    display: block;
    margin-top: 20px;
}

.custom-drop-wrap .dropdown-btn {
    width: 100%;
    border: none !important;
    background: transparent !important;
    font-size: 16px;
    color: #fff !important;
}


.custom-drop-wrap .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    left: auto;
}

.custom-drop-wrap .dropdown-menu.show {
    width: 100%;
    opacity: 1;
    backdrop-filter: blur(45px);
}

.orange-btn {
    background: #CB8B15 !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    max-width: 180px !important;
}

.box-image {
    text-align: center;
}

.box-image img {
    padding: 35px 0;
    /* border-radius: 50%; */
    width: 85px;
    object-fit: cover;
}

.equipo-modal-content {
    max-width: 500px;
}

.custom-pop .matches-history-card {
    padding: 10px 15px !important;
    border: 1px solid #FEF6F2 !important;
    border-radius: 20px !important;
    height: 660px;
}

.custom-pop .modal-content {
    max-width: 480px !important;
    border: none !important;
    box-shadow: none !important;
}

.argent-head {
    text-align: center;
    margin: 10px 0;
}

.argent-head h4 {
    font-size: 16px;
    color: #FEF6F2;
    font-weight: 900;
}

.custom-blue-btn {
    padding: 10px 12px !important;
    font-size: 13px !important;
    color: #FEF6F2 !important;
    max-width: 180px !important;
    border-radius: 20px !important;
}

.custom-dropdown-btn-wrap {
    display: flex;
    gap: 20px;
}

.team-flag-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
}

.dropdown-menu.show {
    width: 100%;
}

.categories-dropdown.season-drop {
    padding-top: 35px;
}

.resultados-div {
    margin-top: 55%;
}

.common-popup .modal-body-padding {
    padding: inherit !important;
}

.youth-classification-td {
    font-size: 12px;
    color: #FEF6F2;
    width: 25px;
    text-align: center;
}

.bg-green {
    background-color: #0D873F;
}

.bg-blue {
    background-color: #1B6FB7;
}

.bg-yellow {
    background-color: #CB8B15;
}

.bg-red {
    background-color: #FF0707;
}


select.dropdown-qualify {
    font-size: 16px;
    font-family: 'Century-Bold';
}

.youthQualify-team-color {
    height: 35px;
    border-bottom: 1px solid #FEF6F2;
    border: 2px solid #FEF6F2;
    font-size: 12px
}

.youthQualify-team-color-bg {
    background-color: #CB8B15;
    border: 2px solid #CB8B15;
}

.dropdown-menu.show {
    overflow-y: auto;
    height: 300px;
}

.manage-select-option input {
    text-align: center;
    min-width: 235px;
}

.custom-player-name-heading input {
    font-family: 'Century-Bold' !important;
}

.equpio-tabs-manage .tab-content {
    overflow: hidden !important;
    height: auto !important;
}

.classification-scroll .fade .tab-pane {
    overflow: auto;
    height: 470px;
}

.drop-height .dropdown-menu.show {
    height: auto;
}

.equpio-tabs-manage .dropdown-menu.show {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
    max-width: auto;
    height: auto;
}

.drop-team-list .dropdown-menu.show {
    height: 400px;
}