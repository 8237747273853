.login-page {
    min-height: 100vh;
    width: 100%;
    background: transparent radial-gradient(closest-side at 50% 50%, #131313 0%, #000000 100%) 0% 0% no-repeat padding-box;
}

.login-wrapper {
    max-width: 360px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
}

.login-wrapper .logo-img {
    max-width: 135px;
    width: 100%;
    height: 135px;
    margin: 0 auto 50px;
}

.login-wrapper .logo-img img {
    width: 100%;
    height: 100%;
}

.login-form-sec .cust-form-control .form-input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #fff;
    background-color: transparent !important;
    outline: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: "Century-Italic";
    color: #EFF6F2;
    padding: 5px 10px;
    margin-bottom: 25px;
}

.login-form-sec .cust-form-control .form-input::placeholder {
    font-size: 16px;
    line-height: 20px;
    font-family: "Century-Italic";
    color: #EFF6F2;
}

.login-form-sec .cust-form-control .form-input:-webkit-autofill,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:hover,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:focus,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
}

/* 
.login-form-sec .cust-form-control .form-input:-internal-autofill-selected {
    background-color: transparent !important;
} 
*/
.login-form-sec .password-sec {
    position: relative;
}

.login-form-sec .password-sec .form-input {
    padding-right: 150px;
}

.login-form-sec .password-sec .forgot-pass-link {
    position: absolute;
    right: 0;
    font-size: 11px;
    line-height: 20px;
    font-family: "Century-Italic";
    color: #EFF6F2;
    bottom: 30px;
}


.forgot-pass-link button{
    position: absolute;
    /* right: 0; */
    font-size: 11px;
    line-height: 20px;
    font-family: "Century-Italic";
    color: #EFF6F2;
    /* bottom: 5px; */
}

.signup-btn-sec .signup-btn {
    background: #0d873f;
    width: 100%;
    padding: 8px 15px;
    border-radius: 50px;
    border: 1px solid #0d873f;
    color: #FEF6F2;
    font-family: 'Century-Bold';
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;
    cursor: pointer;
    margin-bottom: 20px;
}

.signup-btn-sec .signup-btn:hover {
    background-color: transparent;
    color: #0d873f;
    transition: all 0.3s;
}

.login-form-sec .contact-admin {
    color: #FEF6F2;
    font-family: 'Century-Bold';
    font-size: 16px;
    line-height: 20px;
}

.signup-btn-sec {
    position: relative;
    margin-top: 20px;
}

.login-form-sec .show-error {
    font-size: 13px;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translate(-50%, 10px);
}

.login-form-sec .cust-form-control .form-input:-webkit-autofill,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:hover,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:focus,
.login-form-sec .cust-form-control .form-input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
}

.forgot-pass-link {
    font-size: 12px;
    margin-top: -15px;
}

.forgot-pass-link button {
    font-size: 12px;
    /* margin-top: -15px; */
    background-color: transparent;
    border: none;
}

.link-Olvidaste {
    margin-bottom: 30px;
}


.link-Olvidaste button{
    margin-bottom: 30px;
}