/* Global css start */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body.main-wrapper {
  font-family: 'Century-Gothic';
  font-weight: 500;
  font-size: 22px;
  line-height: 14px;
  color: #FEF6F2 ;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: transparent radial-gradient(closest-side at 50% 50%, #131313 0%, #000000 100%) 0% 0% no-repeat padding-box;
  min-height: 100vh;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 16px;
}

ul{
  list-style: none;
  padding: 0;
}

/* Common css */
.dashboard{
  min-height: 80vh;
}
.dashboard-container{
  padding: 87px 15px;
}

.main-title{
  font-size: 21px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Felix-Titling';
}
.header-title{
  text-transform: uppercase;
}
.inter-page-header-title{
  font-family: 'Felix-Titling';
}
/* common input form css */

.common-form-control {
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 60px;
  text-align: center;
  padding: 15px;
  margin-bottom: 20px;
  height: 80px;
  color: #131313;
}

.common-form-control label{
  font-size: 19px;
  line-height: 20px;
  font-family: 'Century-BoldItalic';
  display: block;
  margin-bottom: 5px;
}
.common-form-control input{
  font-size: 16px;
  line-height: 20px;
  font-family: 'Century-Italic';
  margin: 0;
  max-width: 400px;
  width: 100%;
  border:0;
  padding: 5px 10px;
  outline: 0;
  text-align: center;
}
.common-form-control input::placeholder{
  text-align: center;
}
.common-form-control select {
  width: 100%;
  border: 0;
  font-size: 16px;
  color: #5e5959;
  outline: none;
  text-align: center;
}

.blue-btn{
  background-color: #005EAA;
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.green-btn{
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #25762E;
  text-transform: uppercase;
}
.red-btn{
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #800B0F;
  text-transform: uppercase;
}
.green-blue-btn{
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px; 
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background:linear-gradient(270deg, #25762E 0%, #25762E 50%, #005EAA 54%, #005EAA 100%) 0% 0% no-repeat padding-box;
}

.common-dash-padding{ 
  padding: 0px 0 10px;
}

.space-between-btn{
  padding: 30px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center-space-btn{
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 30px 0 20px;
}
.grid-list-sec {
  display: flex;
  justify-content: space-between;
  max-width: 890px;
  width: 100%;
  margin: 30px auto 0;
}
.swal2-popup .swal2-title{
  font-size: 24px;
  font-family: 'Century-Bold';
  font-style: normal;
}
.swal2-popup .swal2-confirm.swal2-styled{
  max-width: 150px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #005EAA;
  min-width: 100px;
}
.swal2-popup .swal2-deny.swal2-styled{
  max-width: 150px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #800B0F;
  min-width: 100px;
}

.swal2-popup .swal2-cancel .swal2-styled{
  max-width: 150px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #800B0F;
  min-width: 100px;
}
.swal2-actions .btn-danger, .swal2-actions .btn-success{
  margin: 0 5px;
}


.cb-items {
  padding-right: 20px; /* Adjust the value to create the desired space */
  /* Rest of your existing styles for scrollbar */
}


.cb-headings {
  font-size: 22px;
  margin-top: 48px;
}


::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
 
  border-radius: 10px;
  background: #131313;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #FEF6F2; 
  border-radius: 30px;
}

.edit-sec {
  font-size: 16px;
    /* text-transform: uppercase; */
    font-family: 'Century-Bold';
}



.search-bar {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%)
}

.search-bar-main {
  position: relative;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.search-bar > input {
  background: #fff;
  padding: 5px 15px !important;
  width: 200px;
  border-radius: 30px;
  color: #000;
  height: 35px;
  padding: 8px;
  font-size: 14px;
  line-height: normal;
  box-sizing: border-box;
  border: unset !important;
}

.search-bar > input::placeholder {
  color: #000;
  font-size: 14px;
  vertical-align: top;
  text-transform: capitalize;
}
.search-bar > input:focus-visible {
  border: unset;
  outline: unset;
}
