.billboard-sec {
    padding: 50px 0;

}

.calendar-list-sec {
    padding: 5px 30px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 400px;
    width: 1000px;
}

.cal-list-card {
    height: 72px;
   max-width:891px;
   width: 100%;
}

.myTeamBanner {
    text-align: center;
}

.billboard-cards {
    max-width: 350px;
    width: 100%;
    margin-bottom: 40px;

}

.billboard-cards h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
}

.billboard-sec-inner {
    height: 700px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.billboard-btn-sec {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 30px 5px 10px;
}

.two-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 5px;
}

.box-bm-1 {
    width: 85px;
    background-color: transparent;
    height: 88px;
    font-size: 9px;
    padding: 8px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    /* margin-right: 7px; */
}

.box-bm-2 {
    width: 85px;
    background-color: transparent;
    height: 88px;
    font-size: 9px;
    padding: 8px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    /* margin-right: 7px; */
}

.logo-content {
    display: flex;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
}

.header-title-bm {
    font-family: 'Felix Titling';
    color: #FEF6F2;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 21px;
}

.cal-list-card .left-sec .event-data h4 {
    font-size: 19px;
    line-height: 14px;
    font-family: 'Century-BoldItalic';
    letter-spacing: 0px;
    font-weight: 200;
    color: #131313;
}

.cal-list-card .left-sec .event-data p {
    font-size: 16px;
    line-height: 13px;
    font-family: 'Century-Italic';
    margin: 0;
}

.news-header-bm {
    display: flex;
    justify-content: space-between;
    max-width: 908px;
    width: 100%;
    margin: 0 auto;
}

.calendar-list-sec-bm {
    padding: 0px 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 433px;
    margin-top: 0px;
    /* width: 950px; */
    width: 100%;
}

.select-bm {
    width: 233px;
    height: 40px;
    background: #131313 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #7c7c7c;
    color: white;
    border-radius: 20px;
    opacity: 1;
    font-size: 16px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    z-index: 999;
    cursor: pointer;
}

.option-bm {
    width: 233px;
    height: 244px;
    background: #131313 0% 0% no-repeat padding-box;
    border: 1px solid #FEF6F2;
    border-radius: 5px;
    opacity: 1;
}

.search-icon-bm {
    margin-right: 22px;
}

.arr-down-bm {
    width: 30px;
    color: white;
}

.comments-sec-bm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 0;
    margin-left: 135px;
}

.options-bm {
    width: 233px;
    height: auto;
    background: #131313 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #7c7c7c;
    border-radius: 5px;
    opacity: 1;
    margin-top: -22px;
    z-index: 0;
    padding-top: 36px;
    line-height: 32px;
    padding-left: 22px;
}

.options-bm p {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    font-style: italic;
}

::-webkit-scrollbar.calendar-list-sec-bm {
    width: 12px;
    /* Set the width of the scrollbar */
    background-color: #f1f1f1;
    /* Set the background color of the scrollbar track */
    border-radius: 6px;
    /* Set the border radius of the scrollbar track */
}

::-webkit-scrollbar-thumb.calendar-list-sec-bm {
    background-color: #131313;
    /* Set the color of the scrollbar thumb */
    border-radius: 6px;
    /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover.calendar-list-sec-bm {
    background-color: #555;
    /* Set the color of the scrollbar thumb on hover */
}

/* .blur-form-sec{
    border-radius: 26px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
    border: 1px solid #FEF6F2;
    padding: 10px 30px;
} */
.blur-form-sec form {
    width: 100%;
}

.blur-form-sec label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
}

.blur-form-control {
    margin-bottom: 15px;
}

.blur-form-sec .blur-input {
    width: 100%;
    border-radius: 50px;
    background-color: #131313;
    border: 0;
    padding: 6px 20px;
    color: #fff;
    font-size: 14px;
    margin-top: 2px;
    border: 1px solid gray;
}

.blur-btn-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.blur-btn-sec .green-btn {
    background: linear-gradient(180deg, #096A31 0%, #0A9042 100%) 0% 0% no-repeat padding-box;
    max-width: 180px;
    border-radius: 50px;
}

.blur-btn-sec .red-btn {
    max-width: 180px;
    border-radius: 50px;
}

.chatPage-sec {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 600px;
}

.msger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 595px;
    width: 100%;
    /* height: calc(100% - 50px); */
    background-color: #131313;
    border: 1px solid #FEF6F2;
}

.partcpt-section {
    border: 0;
}

.chatPage-sec p {
    margin: 0;
}

.msger .msger-header-options button {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    overflow: hidden;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    border-color: transparent;
    text-align: left;
    border-width: 0px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
    padding: 0;
    cursor: pointer;
    border-style: solid;
}

.participants-list {
    padding: 0;
    margin: 0;
}

.participants-list li .partcp-profile {
    padding: 15px 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.partcp-profile .partcp-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
}

.partcp-profile .partcp-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.partcp-profile .partcp-name {
    font-size: 13px;
    line-height: 20px;
    margin: 0 5px;
}

.partcp-profile .partcp-admin {
    font-size: 11px;
}

.partcp-profile .partcp-profile {
    width: 17px;
}

.partcp-profile .partcp-profile img {
    width: 100%;
    height: 100%;
}

.msger .input-form {
    border-top: 1px solid #FEF6F2;
}

.msger .input-form>p {
    font-size: 12px;
    margin-bottom: 10px;
}

.msger .input-form>p a {
    text-transform: none;
    color: #3C80D2;
}

.msger .input-form-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    border-top: 1px solid #FEF6F2;
    align-items: center;
}

.msger .input-form-wrap form {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    position: relative;
    margin-top: -1px;
}

.msger .input-form-wrap form input[type=text] {
    background-color: #131313;
    padding: 13px 13px 13px 50px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    color: white;
}

.msger .input-form-wrap form input[type=text]:focus {
    outline: none;
    border: none;
}

.msger .input-form-wrap form .msger-attachment-btn {
    position: absolute;
    top: 13px;
    border: none;
    background: none;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn {
    left: 10px;
    top: 10px;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn svg {
    color: #fff;
    font-size: 30px;
}

.msger .input-form-wrap form .msger-attachment-btn.right-btn {
    right: 10px;
}

.msger .input-button-wrap button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #EEEDF1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: none;
}

.msger-header {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    padding: 15px 15px;
    border-bottom: var(--border);
    border-bottom: 1px solid #FEF6F2;
    gap: 25px;
}

.msger-chat {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow-y: auto;
    padding: 10px 70px 10px 25px;
    position: relative;
    max-height: 60vh;
}

.msger-chat .msg-img {
    width: 40px;
    height: 40px;
}

.msger-chat .msg-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 50%;
}

.msger-chat .msg {
    position: relative;
    margin-bottom: 10px;
}

.msger-chat .msg-img {
    width: 40px;
    height: 40px;
    background: #ddd;
    border-radius: 50%;
    position: absolute;
    top: 0;
    max-width: 650px;
}

.msger-chat .msg-time {
    font-size: 12px;
    color: #8a8d91;
    display: block;
    margin-bottom: 3px;
}

.msger-chat .msg-bubble .msg-text {
    padding: 10px 12px;
    margin-bottom: 1px;
    font-size: 14px;
    display: inline-block;
}

.msger-chat .msg-bubble .msg-text p {
    margin: 0;
}

.msger-chat .msg-bubble .msg-text a {
    color: inherit;
    display: flex;
}

.msger-chat .msg-bubble .msg-quote p {
    font-style: italic;
    padding-left: 15px;
}

.msger-chat .msg-bubble .msg-quote span {
    font-size: 12px;
    display: block;
    padding-left: 15px;
    font-weight: 300;
}

.msger-chat .msg-item {
    margin-bottom: 15px;
}

.msger-chat .left-msg {
    padding-left: 60px;
    text-align: left;
}

.msger-chat .left-msg .msg-img {
    left: 0;
}

.msger-chat .left-msg .msg-item .msg-bubble .msg-text {
    background: #1B6FB7;
    border-radius: 10px;
}

/* .msger-chat .left-msg .msg-item .msg-bubble:last-of-type .msg-text {
    border-top-left-radius: 10px;
} */

.msger-chat .right-msg {
    text-align: right;
}

.msger-chat .right-msg .msg-img {
    right: 0;
    margin-right: -55px;
}

.msger-chat .right-msg .msg-item .msg-bubble .msg-text {
    background: #fff;
    border-radius: 10px;
    color: #000;
}

/* .msger-chat .right-msg .msg-item .msg-bubble:first-of-type .msg-text {
    border-top-right-radius: 10px;
} */

.msger-chat::-webkit-scrollbar {
    width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
    background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
    background: #bdbdbd;
}

.participatns-sec {
    max-width: 235px;
    width: 100%;
    position: relative;
    flex-flow: column wrap;
    height: 100%;
    background-color: #131313;
    border: 1px solid #FEF6F2;
}

.tabGallerySec {
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
}

.tabGallery-sec {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 30px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 400px;
}

.tabGallery-sec img,
.tabGallery-sec video {
    width: 100%;
    height: 100%;
}

.tabGallerySec .tabgalleryList {
    max-width: 310px;
    width: 100%;
    margin: 20px auto;
    border: 0;
    border-bottom: 0;
    background-color: #CB8B15;
    border-radius: 50px;

}

.tabgalleryList .nav-link.active {
    background: #FEF6F2;
    color: #000;
    border-radius: 50px;
    border: 0;
    font-size: 15px;
    line-height: 20px;
}

.tabgalleryList.nav-tabs .nav-link.active,
.tabgalleryList.nav-tabs .nav-item.show .nav-link {
    border: 0;
    font-size: 15px;
    border: unset !important;
    line-height: 20px;
}

.tabGallerySec .tabgalleryList .nav-link {
    background: transparent;
    font-size: 15px;
    line-height: 20px;
    font-family: 'Century-Bold';
    padding: 5px 20px;
    color: #fff;
    border: 0;
    border-radius: 50px;

}

.tabGallerySec .tabgalleryList .nav-link:hover {
    font-size: 15px;
    line-height: 20px;
    border-color: none;
    background: #FEF6F2;
    color: #000;
    border-radius: 50px;
}



@media (max-width: 600px) {

    .msger {
        height: 100vh;
    }

    .msger .input-form-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .msger-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 15px;
        padding-top: 20px;
    }
}


.boardMeeting-popup .modal-content {
    max-width: 900px;
}

.boardMeeting-popup .modal-footer .form-group {
    justify-content: space-between !important;
    width: 100%;
}

.boardMeeting-popup .modal-footer .form-group button {
    max-width: 130px;
    font: 12px;
}

.blur-form-list>img:first-child {
    margin-right: 5px;
}

.profile-img {
    border-radius: 20px;
    padding: 0 20px;
}

/* .add-users-list.add-list-active {
    display: block;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999999999;
} */

.add-users-list.add-list-active {
    display: block;
    position: absolute;
    top: auto;
    left: 30%;
    z-index: 9999999999;
    /* bottom: -160px; */
}

.add-users-list.add-list-active.debateRoom {
    display: block;
    position: absolute;
    top: auto;
    left: 18%;
    z-index: 9999999999;
    bottom: -160px;
}

.add-users-list {
    max-width: 330px;
    width: 100%;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: 1s;
    z-index: 99;
    background: #00000096;
    border-radius: 1px 0px 26px 26px;
    box-shadow: 1px 1px 1px #000;
    display: none;
    height: auto;
}

.two-box img {
    width: 44px;
    height: 23px;
    object-fit: contain;
    margin: 0 auto 5px;
}

.logo-content {
    padding: 0;
    text-align: center;
    font-size: 8px;
    margin: 0;
}

.chat_img {
    width: 180px;
}

.add-member-icon {
    border: 1px solid white;
    border-radius: 50%;
}

.add-users-list.add-member-active {
    display: block;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%);
}

.debate-page .cal-list-card {
    margin-bottom: 0;
}

.debate-page .cal-list-card {
    width: 100%;
    padding: 10px;
}

.debate-page .chatPage-sec p {
    /* font-size: 16px; */
    font-weight: 700;
    line-height: 20px;
}

.debate-page .participants-list li .partcp-profile:not(:first-child) {
    padding: 20px 10px 0;

}

.debate-page .member-searchBox input {
    background: #131313;
    color: #fff;
    padding: 5px 10px;

    font-size: 16px;
    width: 100%;
    border: 1px solid #fff;
    border-width: 0 0px 1px 0px;
}

.debate-page .add-member-icon svg {
    width: 18px;
    height: 18px;
}

.debate-page .partcp-profile .partcp-img {
    overflow: auto;
}

.debate-page .partcp-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 130px;
    font-size: 13px;
}

.debate-page .partcp-profile .partcp-img>img {
    width: 25px;
    height: 25px;
    object-fit: cover;
    border-radius: 50%;
}

.partcp-profile .partcp-img {
    width: auto;
    height: auto;
    border-radius: unset;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}


.debate-page .participants-list {
    padding: 0;
    margin: 0;
    overflow: auto;
    height: calc(100% - 82px);
    padding-bottom: 5px;
}


.debate-page .msger-chat .msg-img {
    top: auto;
    bottom: 0;
    width: 24px;
    height: 24px;
    margin: 0;
}

.debate-page .msg-text p {
    text-align: left;
}

.debate-page .msger-chat .left-msg {
    padding-left: 40px;
    text-align: left;
}

.debate-page .msger-chat {
    padding: 10px 25px 10px 25px;
    height: calc(100% - 107px);
    max-height: 100%;
}

.msger-chat .right-msg {
    padding-right: 40px;
}

.debate-page .msger {
    display: unset;
    position: relative;
    max-width: 595px;
    width: 100%;
    height: calc(100% - 50px);
    background-color: #131313;
    height: 600px;
}

.debate-page .msger .input-form {
    height: 55px;
    position: absolute;
    width: 100%;
    bottom: 0;
}


.debate-page h3.header-title {
    font-size: 21px;
    font-weight: 700;
}

.debate-page .legend-tabs>.nav-tabs li {
    max-width: 208px;
}


.debate-page .legend-tabs>.nav-tabs {
    border-bottom: unset;
    justify-content: center;
    gap: 20px;
}

body .debate-page .legend-tabs>.nav-tabs li button {
    border-bottom: 3px solid #131313 !important;
    font-size: 19px;
    font-weight: 700;
}

body .debate-page .legend-tabs>.nav-tabs li button.active {
    border-bottom: 3px solid #EBA51A !important;
}


.legend-tabs .nav-tabs .nav-link.active::before {
    content: unset;
}

.debate-page .add-users-list {
    border-radius: 26px;
    max-width: 400px;
    height: 350px;
}

.debate-page .legend-box ul {
    height: 100%;
    overflow: auto;
}

.debate-page .legendBtn {
    height: 50px;
}

.debate-page .legend-box {
    height: 100%;
    padding: 15px 15px 50px 15px;
}



.highlight {
    border-radius: 5px;
    background: #ffffff29;
}


.previewMedia * {
    width: 80px;
    min-width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    padding: 1px;
    background: #fff;
    margin: 0 5px;
}

.previewMedia {
    position: absolute;
    bottom: 54px;
    width: calc(100% - 7px);
    padding: 10px;
    background: #131313;
    box-shadow: rgb(255 255 255 / 28%) 0px 0px 2px;
    border-radius: 10px 10px 0 0;
    display: flex;
    overflow: auto;
    max-width: 600px;
}

.msger-chat .right-msg .msg-text {
    position: relative;
}

.msger-chat .right-msg .msg-text:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    bottom: 10px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 15px 8px;
    border-color: transparent transparent #fff transparent;
    -webkit-transform: rotate(37deg);
    -ms-transform: rotate(37deg);
    transform: rotate(2deg);

}


.msger-chat .left-msg .msg-text {
    position: relative;
}

.msger-chat .left-msg .msg-text:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    bottom: 10px;
    left: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 15px 9px;
    border-color: transparent transparent #1B6FB7 transparent;
    transform: rotate(2deg);
}

.msger-chat .msg-bubble .msg-text p {
    word-break: break-all;
}

.user-add-members img {
    width: 40px;
    border-radius: 50%;

}

.group-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.tabGallerySec .tabgalleryList>li {
    max-width: 33.33%;
    width: 100%;
    text-align: center;
    min-height: 30px;
}

.tabGallerySec .tabgalleryList>li button {
    width: 100%;
}

.tabGallerySec .tabgalleryList>li button.active {
    background-color: #fff !important;
    color: #000 !important;
}


.tabGallery-sec .img-card>img,
.tabGallery-sec .img-card>video {
    width: 80px;
    height: 80px;
    padding: 0 3px;
    object-fit: cover;
}

.group-user-list {
    margin: 225px auto;
    height: 500px;
    overflow: auto;
}

.board-meeting-page .space-between-btn {
    justify-content: center;
}

.addboardingsec .blur-form-list {
    justify-content: center;
}

.addboardingsec .blur-form-list>img {
    object-fit: contain;
}

.user-add-members>img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    margin: 0;
}

.showbox .left-btn svg {
    font-size: 16px !important;
}

.showbox .left-btn button.msger-attachment-btn {
    top: 15px !important;
    background: red;
}

.showbox button.msger-attachment-btn {
    top: 8px !important;
}

.showbox button.msger-attachment-btn>img {
    width: 15px;
}

.table-list-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;

}

.bar-chart-content>.bar-char-head {
    font-size: 16px;
    background: #cb8b15;
    padding: 13px;
    border: 1px solid #fff;
    border-width: 1px 1px 0 1px;
}

.bar-chart-content>.bar-char-body {
    border: 1px solid #fff;
    border-radius: 0 0 5px 5px;
    background: #131313;
    font-size: 14px;
    padding: 10px;
    line-height: normal;
    font-family: 'Century-Italic';
    font-weight: 400;
}

.bar-char-body svg text {
    fill: #fff;
}

.bar-chart-content {
    width: 100%;
    height: 100%;
    max-width: 280px;
    /* position: relative; */
    padding-top: 228px;
    text-align: center;
    margin: 0 auto;
}

.typing-gif {
    border-radius: 35%;
    height: 35px;
    width: 52px;
}

/* .msg-bubble {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
} */

.left-mess-box-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.right-mess-box-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: row-reverse;
}

.debate-room-profile {
    display: flex;
    justify-content: space-evenly;
}


.upper-div-section {
    margin-top: 20px;
    border: 1px solid white;
    width: 80%;
    border-radius: 6px;
    margin-bottom: 5px;
}

a.sc-gWHiUp.jVfUnx.microlink_card {
    background-color: transparent;
    color: white;
    border-radius: 6px;
    padding: 5px;
    /* margin-top: 5px; */
    /* width: 80%; */
    border: none;
}

a {
    word-break: break-all;
}

.link-middle-secion {
    padding-left: 5px;
}

.lower-link-section {
    border: 1px solid white;
    color: black;
    background-color: white;
    margin-top: 0px;
    /* width: 80%; */
    font-size: 14px;
    padding: 6px;
}

.link-main-sec {
    margin-left: 127px;
}

/* .legend-tabs .tab-content {
    padding: 30px 10px 10px;
    overflow-y: hidden;
    height: inherit;
    overflow-x: hidden;
} */
.proposal-Scroll {
    overflow: auto;
    height: 550px !important;
    padding: 10px 10px 10px;
    overflow-x: hidden;
    max-height: inherit !important;
}

.chat-sec-manage .tab-content {
    overflow: hidden;
    height: auto;
}

.tab-custom-color-blue.nav.nav-tabs {
    background: #1B6FB7;
}

.chat-custom-color-yellow .msg-text {
    background: #CB8B15 !important;
}

.chat-custom-color-yellow .msg-text:before {
    border-color: transparent transparent #CB8B15 transparent !important;
}

.cutom-image-manage img {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.custom-popup-manage .modal-content {
    max-width: 950px;
}

.mesa-chica-add-member {
    left: 35% !important;
    bottom: -200px !important;
}

.doc-visual {
    width: 100%;
    height: 50px;
}

/* .chatPage-sec {
    display: visible  !important;
} */

.doc-preview {
    /* background-color: transparent; */
    display: flex;
    width: 100%;
    color: black;
    font-size: 12px;
}

.doc-para {
    flex-grow: 2;
}

.doc-preview-manage {
    color: white;
    width: 50px;
    height: 50px;
    margin: 5px;
    padding: 5px;
}


@media screen and (max-width: 1450px) {


    .comments-sec-bm {
        margin-left: 0;
    }

}

@media screen and (max-width: 991px) {

    .home-empty-col.col {
        display: none;
    }

    .chat-sec-responsive.row {
        display: block;
    }

    .cal-list-card {
        width: 100%;
    }
    .calendar-list-sec-bm {
        width: 100%;
    }
}



