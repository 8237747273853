.event-page-content {
    padding: 50px 0 20px;
}

.event-calendar-sec {
    padding: 80px 0 20px;
}

.event-calendar .react-calendar {
    background-color: black;
    padding: 5px;
    max-width: 530px;
    width: 100%;
    border-radius: 30px;
}

.event-calendar .react-calendar button {
    color: #fff;

}

.event-calendar .react-calendar .react-calendar__tile {
    background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile abbr {
    width: 40px;
    height: 40px;
    display: inline-block;
    padding: 8px 5px;
    text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile--active abbr {
    background-color: rgb(255, 171, 171);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    padding: 8px 5px;
    text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus {
    background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover abbr,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus abbr {
    background-color: red;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    padding: 8px 5px;
    text-align: center;
}

.cal-list-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px 20px 10px 8px;
    color: #131313;
    margin-bottom: 15px;
}

.cal-list-card .left-sec {
    display: flex;
    align-items: center;
}

.cal-list-card .left-sec .date {
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    padding: 15px 5px;
    font-size: 17px;
    line-height: 17px;
    color: #fff;
    margin-right: 15px;
}

.cal-list-card .left-sec .event-data h4 {
    font-size: 13px;
    line-height: 14px;
    font-family: 'Century-BoldItalic';
}

.cal-list-card .left-sec .event-data p {
    font-size: 10px;
    line-height: 13px;
    font-family: 'Century-Italic';
    margin: 0;
}

.cal-list-card .right-sec h3 {
    font-size: 19px;
    line-height: 14px;
    font-family: 'Century-BoldItalic';
    margin: 0;
}

.event-btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 30px 5px 10px;
}

.calendar-list-sec {
    padding: 5px 30px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 400px;
}

.calendar-list-sec .cal-month h4 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
    margin-bottom: 20px;
}

.event-calendar-sec .react-calendar__navigation button:enabled:hover,
.event-calendar-sec .react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.op-31 {
    opacity: 31%;
}



.custom-container {
    width: 100%;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: #000;
}

.custom-calendar span {
    color: #fff;
}

.rmdp-shadow {
    box-shadow: none;
    border-radius: 15px !important;
}


.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
    background-color: #000;
    box-shadow: unset;
    border: 1px solid #fef6f257;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
    border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
    border-bottom: 1px solid bisque;
}

.custom-calendar,
.custom-calendar .rmdp-calendar,
.custom-calendar .rmdp-day-picker,
.custom-calendar .rmdp-day-picker>div {
    width: 100% !important;
}

.custom-calendar .rmdp-day,
.custom-calendar .rmdp-week-day {
    color: #fff;
}

.custom-calendar .rmdp-arrow {
    border: solid #fff;
    border-width: 0px 2px 2px 0px;
    display: inline-block;
    height: 10px;
    margin-top: 5px;
    padding: 2px;
    width: 10px;
}

.custom-calendar .rmdp-arrow-container {
    background: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 5px;
    padding: 0;
    width: 40px;
    align-items: center;
}

/* 
.custom-calendar .rmdp-border-bottom {
    border-bottom: 1px solid #000;
} */


.eventColor_list ul li a {
    font-size: 11px;
    color: #fff;
}

.eventColor_list ul li span {
    font-size: 15px;
}

.clr-circle {
    width: 19px;
    height: 19px;
    display: inline-block;
    margin-right: 10px;
    background: transparent;
    border-radius: 50%;
}

.eventColor_list ul li {
    display: flex;
    align-items: center;
}

.yellow-clr {
    background-color: #DF9825;
}

.red-clr {
    background-color: #800B0F;
}

.blue-clr {
    background-color: #54A3C9;
}

.green-clr {
    background-color: #25762E;
}

.custom-calendar .rmdp-border-bottom {
    border-bottom: 0;
}

.custom-calendar .rmdp-colors {
    width: 0;
    height: 0;
    padding: 0;
}

.custom-calendar .rmdp-color.active {
    box-shadow: unset;
}

.cal-list-card {
    height: 72px;
    width: 835px;
}

.calendar-list-sec-box {
    padding: 5px 30px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 400px;
    width: 892px;
}

.event-classification {
    font-size: x-small;
}

.dot-red {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #800B0F;
}

.dot-yellow {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #DF9825;
}

.dot-blue {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #54A3C9;
}

.dot-green {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #25762E;
}

.event-click:active {
    transform: scale(0.9);
}

.event-div {
    padding: 10px;
    background-color: #a8a4a4;
    color: #1a1a1a;
    border: 3px solid #800B0F;
}

/*********  event-edit-box  *********/
.event-edit-box {
    border: 1px solid #FEF6F2;
    padding: 32px 30px;
    border-radius: 20px;
}

.event-edit-box {
    border: 1px solid #FEF6F2;
    padding: 32px 30px;
    border-radius: 20px;
    font-size: 13px;

}

.day-box {
    font-weight: 700;
}

.edit-box-date,
.event-name-box {
    display: flex;
    gap: 10px;
    align-items: center;
}

.mb-20 {
    margin-bottom: 20px;
}

.date-box {
    width: 28px;
    height: 28px;
    background: #CB8B15;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.event-name {
    font-weight: 700;
}

/* .event-box-content p {
    font-size: 14px;
    line-height: 15px;
    font-style: italic;
} */

.event-box-content p {
    font-size: 14px;
    line-height: 15px;
    font-style: italic;
    height: 100%;
    max-height: 400px;
    overflow-y: auto;
    /* margin: 30px 5px; */
}

.event-box-btn button {
    border-radius: 30px;
}

.event-box-img {
    border-radius: 16px;
}

.event-box-img img {
    width: 100%;
}

/*********  event-edit-box  *********/

.event-link-box {
    word-wrap: break-word;
    border: 1px solid white;
    border-radius: 15px;
    padding: 10px;
}

.event-list-sec {
    width: 100% !important;
}

/* .event-box-img img {
    width: 100%;
    height: 180px;
    object-fit: cover;
} */

.event-box-img img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    border-radius: 8px;
}

.custom-list-manage {
    padding: 10px 0;
}

.custom-month-manage {
    padding: 10px 0;
}

.equpio-tabs-manage .tab-content {
    overflow: hidden !important;
}

/* .chatPage-sec {
    display: none  !important;
} */

.showbox {
    display: block !important;
}

.rmdp-ym {
    background-color: #000;
}

.rmdp-month-picker,
.rmdp-year-picker {
    background-color: #000 !important;
    border-radius: 0 0 5px 5px;
    bottom: 2px;
    left: 2px;
    position: absolute;
    right: 2px;
    top: 2px;
}

.upper-manage-section {
    margin-top: 20px;
    border: 1px solid white;
    /* width: 80%; */
    border-radius: 6px;
    margin-bottom: 5px;
}