.dashboard-card-secs {
    padding: 80px 0 0;
}

.control-card {
    max-width: 200px;
    width: 100%;
    border: 0.30000001192092896px solid #FEF6F2;
    border-radius: 5px 5px 5px 5px;
    background: #000000 0% 0% no-repeat padding-box;
    overflow: hidden;
    margin-bottom: 20px;
    /* height: 200px;
    background-color: white; */
}

.control-card .card-img {
    text-align: center;
    padding: 30px 5px;
}

.control-card .card-body {
    background: #FEF6F2 0% 0% no-repeat padding-box;
    padding: 10px;

}

.control-card .card-body p {
    margin: 0;
    color: #131313;
    font-size: 14px;
    line-height: 18px;
}

.control-card .card-body h4 {
    margin: 0;
    color: #131313;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 2px;
    font-family: 'Century-Bold';
    word-wrap: break-word;
}

.control-card .card-img img {
    width: 60px;
    height: 60px;
}

.manage-team-sec {
    background: #131313 0% 0% no-repeat padding-box;
    border: 0.30000001192092896px solid #FEF6F2;
    border-radius: 5px;
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    padding: 15px 10px;
    text-align: center;
    max-height: 550px;
    height: auto;
    overflow: auto;
}

.manage-team-sec h5 {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 5px;
}

.manage-team-sec p {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Century-Italic';
    margin: 10px 0 0 0;
}

.team-list ul {
    padding: 0;
    margin: 10px 0;
    overflow-y: hidden;
    height: auto;
    margin-right: -8px;
}

.team-list-single ul {
    padding: 0;
    margin: 10px 0;
}

.team-list ul .team-list-link {
    font-size: 13px;
    color: #FEF6F2;
    font-family: 'Century-Italic';
    display: flex;
    text-align: left;
    align-items: center;
    padding: 5px 0;
}

.team-list ul .team-list-link .team-list-img {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-list-link {
    cursor: pointer;
}

.flag-div {
    background: #131313 0% 0% no-repeat padding-box;
    /* border: 0.30000001192092896px solid #FEF6F2; */
    border: 0.3px solid #7c7c7c;
    border-radius: 5px;
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    padding: 15px 10px;
    text-align: center;
    margin-right: 40%;
    margin-top: 20%;
}

.team-name {
    margin-top: 20px;
    font-size: medium;
    font-family: 'Century-Italic';

}

/* .flag-img-home {
    width: 100%;
    max-width: 40px;
} */

.flag-img-home {
    /* position: absolute; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    width: 100%;
    height: 100%;
    max-width: 50px;
    object-fit: contain;
    max-height: 40px;
}

.team-list-name {
    margin-left: 12px;

}

/* ======================popups===================== */

.swal2-popup {
    display: grid;
    background-color: rgba(0, 0, 0, 0.959);
    border: 2px white solid;
    color: white;
    padding: 50px;
    font-family: 'Century-Gothic';
    border-radius: 20px;
    font-weight: 300;
    margin-left: -16px;
}

.swal2-title {
    font-family: 'Century-Gothic';
    font-weight: 300;
    font-style: italic;
    line-height: normal;
}

.swal2-confirmswal2-styled {
    background-color: #131313;
}

.swal2-confirm.swal2-styled {
    background-color: rgba(231, 27, 68, 0.88);
    color: white;
    border: none;
    min-width: 60%;
}

.swal2-deny.swal2-styled {
    background-color: #EBB62E;
    color: white;
    border: none;
    min-width: 60%;
}

.swal2-confirm.swal2-styled:active {
    border: none;
}

.manage-team-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.manage-team-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.team-list-char-sec {
    position: relative;
    height: 100% !important;
}

.piechart-sec {
    margin-top: 150px;
    position: absolute;
    bottom: -15% !important;
}

.dashboard-card-secs {
    padding: 30px 0 0;
}

.control-card {
    max-width: 200px;
    width: 100%;
    border: 0.30000001192092896px solid #FEF6F2;
    border-radius: 5px 5px 5px 5px;
    background: #000000 0% 0% no-repeat padding-box;
    overflow: hidden;
    margin-bottom: 20px;
}

.control-card .card-img {
    text-align: center;
    padding: 30px 5px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.control-card .card-body {
    background: #FEF6F2 0% 0% no-repeat padding-box;
    padding: 10px;

}

.control-card .card-body p {
    margin: 0;
    color: #131313;
    font-size: 14px;
    line-height: 18px;
}

.control-card .card-body h4 {
    margin: 0;
    color: #131313;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 2px;
    font-family: 'Century-Bold';
}

/* .control-card .card-img img {
    width: auto;
    height: 60px;
} */

.manage-team-sec {
    background: #131313;
    border: 1px solid #FEF6F2;
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
    margin-left: auto;
    padding: 15px 10px;
    text-align: center;
    margin-top: 20%;
    margin-right: 40%;
    max-height: 55%;
}

.manage-team-sec h5 {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 5px;
}

.manage-team-sec p {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Century-Italic';
    margin: 10px 0 0 0;
}

.team-list ul {
    padding: 0;
    margin: 10px 0;
}

.team-list ul .team-list-link {
    font-size: 13px;
    color: #FEF6F2;
    font-family: 'Century-Italic';
    display: flex;
    text-align: left;
    align-items: center;
    padding: 5px 0;
}

.team-list ul .team-list-link .team-list-img {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-list-link {
    cursor: pointer;
}


/* ======================popups===================== */

.swal2-popup {
    display: grid;
    background-color: rgba(0, 0, 0, 0.959);
    border: 2px white solid;
    color: white;
    padding: 50px;
    font-family: 'Century-Gothic';
    border-radius: 20px;
    font-weight: 300;
    margin-left: -16px;
}

.swal2-title {
    font-family: 'Century-Gothic';
    font-weight: 300;
    font-style: italic;
    line-height: normal;
}

.swal2-confirmswal2-styled {
    background-color: #131313;
}

.swal2-confirm.swal2-styled {
    background-color: rgba(231, 27, 68, 0.88);
    color: white;
    border: none;
    min-width: 60%;
}

.swal2-deny.swal2-styled {
    background-color: #EBB62E;
    color: white;
    border: none;
    min-width: 60%;
}

.swal2-confirm.swal2-styled:active {
    border: none;
}

.manage-team-img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
}

.manage-team-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.team-list-char-sec {
    position: relative;
    height: 100vh;
}

.piechart-sec {
    margin-bottom: 160px;
    position: absolute;
    bottom: 0;
}

.club-popup .modal-dialog {
    max-width: 615px;
    width: 100%;
    max-height: 500px;
    height: 100%;
    box-shadow: 2px 2px 3px #000000;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: 1s;
    z-index: 99;
    background: transparent;
    border: 1px solid #FEF6F2;
    border-radius: 15px;
}

.club-popup .modal-body,
.club-popup .modal-content {
    border-radius: 0;
    background: unset !important;
    border: unset !important;
    height: 100%;
}

.club-popup label {
    font-size: 12px;
}


.club-selection {
    background-color: #131313;
    border: 1px solid #4F4F4F;
    border-radius: 15px;
    width: 100%;
}

.mb-30 {
    margin-bottom: 30px;
}

.club-popup .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* 
.club-popup .modal-body {
    height: 100%;
} */

.club-popup .modal-footer>div {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 30px;
}

.club-popup .modal-footer>div button {
    max-width: 100px;
    font-size: 12px;
    border-radius: 30px;
}

.color-picker-btn input {
    max-width: 240px;
    width: 120px;
    height: 40px;
    border-radius: 21px;
    border: none;
    cursor: pointer;
    margin-top: -16px;
}

.color-picker-btn input::-webkit-color-swatch {
    border-radius: 21px;
    border: transparent;
}

.color-picker-btn input::-webkit-color-swatch-wrapper {
    border-radius: 21px;
}

.color-picker-btn {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;

}

/* .color-picker-btn .plus-icon {
    border: 2px solid #fff;
    padding: 5px;
    border-radius: 50%;
} */

.color-picker-btn .plus-icon {
    /* border: 2px solid #fff; */
    /* padding: 5px; */
    border-radius: 50%;
    /* background: white; */
}

.club-popup .footer-sec {
    max-width: 400px;
    margin: 50px auto 0;
}


.dashboard-card-secs .row>.col-lg-3 {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 15px !important; */
}


.control-card .card-body {
    height: 100%;
}

.control-card {
    height: 100%;
}

.img-edit {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    top: 130px;
    left: 10px;
}

.img-edit {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    color: black;
    z-index: 999;
}

.selection-input>input,
.select-clrName input {
    padding: 10px;
    min-height: 40px;
    font-size: 14px;
}

.select-clrImg .color-picker-btn {
    display: flex;
    width: 100%;
    height: 100%;
}

.select-clrImg {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.select-clrImg .color-picker-btn {
    max-width: 50px;
    margin: 0;
    height: 50px;
    position: relative;
}

.select-clrImg .select-clrName {
    max-width: calc(100% - 70px);
    width: 100%;
}

.select-clrImg .color-picker-btn>.img-edit {
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    border-radius: 10px;
}

.select-clrImg .color-picker-btn>.img-edit>input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.control-card {
    border: none;
}

.control-card .card-body {
    background: #202020;
}

.control-card .card-body p,
.control-card .card-body h4 {
    color: #FEF6F2 !important;
}

.control-card .card-body h4 {
    font-size: 15px;
}

.control-card .card-body p {
    font-size: 10px;
}

.piechart-sec h6 {
    font-size: 14px;
    font-family: "century-bold";
}

.edit-news-sec .editnews-Content h4,
.myTeamBanner h4 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
}

.cross-icon {
    background: white;
    border-radius: 50%;
    margin-left: 82%;
    border: solid black 2px;
    cursor: pointer;
}

/* .plus-icon {
    color: white;
    width: 51px;
    background: white;
} */

.plus-icon {
    color: white;
    background: white;
    width: 32px;
    cursor: pointer;
}

.hiddenBox {
    /* visibility: hidden; */
    display: none;
}

select.theme-selection {
    word-wrap: normal;
    margin-left: 43%;
    background: transparent;
    color: white;
    /* border-radius: 21%; */
    width: 32%;
    font-size: 16px;
}

.select-clrImg .color-picker-btn>.img-edit-upload {
    width: 70%;
    height: 70%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    border-radius: 10px;
    margin-left: 15px;
    margin-top: 6px;
}

select.theme-selection:required:invalid {
    color: #596;
}

.theme-selection option[value=""][disabled] {
    display: none;
}

.theme-selection option {
    color: #000;
}

/* .brand-ambassador-upper{
    height: 200px;

    border: 1px solid white;
    border-radius: 6px;
} */

.swiper-pagination-bullet-active {
    background-color: #fff !important;
}

.banner-swip .swiper-pagination {
    position: sticky;
}

.outer-ambassador {
    text-align: center;
    padding: 5px;
    /* margin-bottom: 50px; */

}

.outer-ambassador img {
    width: 200px;
    /* padding: 5px; */
    /* margin-bottom: 50px; */
    height: 220px;
    object-fit: contain;
}

.upper-ambassadore-box {
    display: flex;
}

/* 
.select-clrName input {
    background-image: url(../../../../public/img/x.svg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    color: #FEF6F2;
}

.select-clrName input img {
    background-color: #FEF6F2;
} */
.ambassador-cross-icon {
    width: 15px !important;
    height: 15px !important;
}




@media screen and (max-width: 991px) {

    .home-empty-col.col {
        display: none;
    }

    .manage-responsive-home.row {
        display: block;
    }

    .team-list-char-sec {
        position: relative;
        height: 100vh;
    }

    .piechart-sec {
        margin-top: 0px;
        position: relative;
        bottom: 0px !important;
    }

}

.dashboard-container {
    padding: 35px 15px 0px !important;
}

.trio-btns {
    margin-top: 30px;
}