.legend-tabs {
    padding: 50px 0 10px;
}

.legend-tabs .nav-tabs {
    border-bottom: 3px solid #131313;
}

.legend-tabs .tab-content {
    padding: 30px 0 10px;
}

.legend-tabs .nav-tabs .nav-link {
    font-size: 15px;
    line-height: 25px;
    color: #FEF6F2;
    text-transform: uppercase;
    border: 0;
    position: relative;
}

.legend-tabs .nav-tabs .nav-link.active {
    background-color: transparent;
    color: #FEF6F2;
    transition: all 0.3s;

}

.legend-tabs .nav-tabs .nav-link::before {
    transition: all 0.3s;
    content: "";
    position: absolute;
    width: 0%;
    height: 3px;
    background-color: #CB8B15;
    left: 0;
    bottom: 0;
    transition: all 0.3s;
}

.legend-tabs>.nav-tabs .nav-link.active::before {
    transition: all 0.3s;
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #CB8B15;
    left: 0;
    bottom: 0;
}

/* .legend-tabs .nav-tabs .nav-link::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #CB8B15;
} */
.legend-tabs .nav-tabs .nav-link.active,
.legend-tabs .nav-tabs .nav-item.show .nav-link {
    border-color: #CB8B15;
    transition: all 0.3s;
}

.historical-legends {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.legend-card {
    max-width: 120px;
    width: 100%;
    margin: 0 auto 20px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: 260px;
}

.legend-card .legend-img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.legend-card .legend-profile {
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 5px 2px;
    align-items: center;
}

.legend-card .legend-profile p {
    font-size: 10px;
    line-height: 13px;
    font-family: 'Century-Bold';
    margin: 0;
    word-wrap: break-word;
    margin-left: 5px;
}

.center-btn-sec {
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
}

.center-btn-sec button {
    text-transform: uppercase;
}

.preview-imgCon {
    margin: 0 auto;
    text-align: center;

}

.preview-imgCon .previewImg {
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    height: 200px;
    text-align: center;
    object-fit: contain;
}

.preview-imgCon img {
    height: auto;
}

.lagend_profile .feild-position-sec p {
    font-size: 12px;
    line-height: 19px;
}

.legend-dropdown {
    max-width: 330px;
    width: 100%;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: 1s;
    z-index: 99;
    background: #00000096;
    border-radius: 1px 0px 26px 26px;
    box-shadow: 1px 1px 1px #000;
    display: none;
}

.addLagendsec .common-form-control {
    position: relative;
}

.legend-dropdown.legend-active {
    display: block;
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
}

.addlegendpopup .modal-dialog,
.addlegendpopup .modal-dialog {
    background: unset;
    border: unset;
    box-shadow: unset;

}

.addlegendpopup .modal-content {
    background: unset;
}

.addlegendpopup .modal-content {
    border: unset;
}

.legendprofile {
    position: relative;
    display: inline-block;
}


.legendstatusbtn {
    position: absolute;
    right: -5px;
    bottom: -10px;
}

.legendlist {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 5px;
    margin: 0 !important;
    font-size: 14px;
}

.legendprofilename {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.legendBtn {
    display: flex;
    gap: 10px;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    justify-content: space-between;
    padding: 10px 25px;
}

.legendBtn button {
    border-radius: 30px;
    font-size: 12px;
    margin-bottom: 0;
    max-width: 80px;
    padding: 4px 12px;
}

.addlegendpopup .modal-body ul {
    max-height: 160px;
    overflow: auto;
}

.legendprofile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}

.legend-box {
    position: relative;
    padding: 15px 15px 40px 15px;
    overflow: auto;
}

.legend-box ul {
    height: 175px;
    overflow: auto;
    padding: 0;
    margin: 0;
}

.legend-box ul li span {
    color: #fff;
}

.legPro-name {
    font-family: 'Century-bold';
}

input#uploadFile\ legenduploadFile {
    padding: 0;
}

.new-legend-sec .profile-img {
    padding: 0;
}

.new-legend-sec .legend-profile {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.new-legend-sec .legend-profile p {
    word-break: break-all;
}




.lpi-sec .user-admin-profile-img {
    overflow: unset;
    width: unset;
    height: unset;
}

.lpi-sec .user-admin-profile-img::before {
    width: 135px;
    height: 115px;
    top: 15px;
    transform: unset;
    margin: 0 auto;
}

.lpi-sec .user-admin-profile-img img {
    width: 120px;
    height: 120px;
}

.legend-profile-pic{
    width: 30px;
    border-radius: 50%;
    height: 30px;
    object-fit: cover;
}
.img-legend{
    width: 30px;
    height: 30px;
    border-radius:50%;
    object-fit: cover;
}
.otr-nation::placeholder{
    text-align: center;
}