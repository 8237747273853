.billboard-sec {
    padding: 50px 0 20px;
}

.myTeamBanner {
    text-align: center;
}

.billboard-cards {
    max-width: 350px;
    width: 100%;
    margin-bottom: 40px;
}

.billboard-cards .card-img {
    height: 190px;
    border-radius: 13px;
    overflow: hidden;
}

.billboard-cards .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.billboard-cards h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
}

.billboard-sec-inner {
    height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
}

.billboard-btn-sec {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 30px 5px 10px;
}

.file-input-sec .fileUpload {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.file-input-sec .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0;
    left: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    max-width: 100%;
}

.file-input-sec .btn--browse {
    border: 1px solid #3a3838;
    outline: none;
    cursor: pointer;
}

.file-input-sec .btn--browse:hover {
    border: 1px solid #3a3838;
    cursor: pointer;
}

.file-input-sec .f-input {
    width: 100%;
    max-width: 400px;
    padding: 10px 14px;
    text-align: center;
    cursor: pointer;
}

.file-input-sec .f-input::placeholder {
    text-align: center;
}

.editorBannerSec {
    height: 510px;
}

.editorBannerSec .billboard-cards .card-img {
    border-radius: 12px;
    overflow: hidden;
}

.editorBannerSec .billboard-cards .card-img img {
    width: 100%;
}

.editorBannerSec .myTeamBanner .card-body {
    margin: 20px 0;
}

.editorBannerSec .myTeamBanner .card-body h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
}

.editorBannerSec .myTeamBanner .card-body p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
}

.card-heading {
    font: normal normal normal 16px/21px Francois One;
    letter-spacing: 0px;
}

.current-banner {
    background: #CB8B15;
    opacity: 1;
}

/* -------- edit section --------- */
.editable-sec {
    background-color: #bdbdbd;
    color: #131313;
    padding: 10px 15px;
    border: 1px solid #000;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Century-Bold' !important;
}

.editnewscard-img {
    max-width: 350px;
    height: 200px;
    margin: 20px auto 0;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
}

.editnewscard-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* -----------list view---------- */

.billboard-page .billboard-sec {
    padding: 50px 60px;
}

.billboard-page .myTeamBanner {
    text-align: left;
}

.billboard-cards {
    max-width: 350px;
    width: 100%;
    margin-bottom: 40px;
}

.billboard-cards .card-img {
    height: 190px;
    border-radius: 13px;
    overflow: hidden;
}

.billboard-cards .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.billboard-cards h3 {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    text-transform: uppercase;
}

.billboard-sec-inner {
    height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
}

.billboard-btn-sec {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 30px 5px 10px;
}

.file-input-sec .fileUpload {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.file-input-sec .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0;
    left: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    max-width: 100%;
}

.file-input-sec .btn--browse {
    border: 1px solid #3a3838;
    outline: none;
    cursor: pointer;
}

.file-input-sec .btn--browse:hover {
    border: 1px solid #3a3838;
    cursor: pointer;
}

.file-input-sec .f-input {
    width: 100%;
    max-width: 400px;
    padding: 10px 14px;
    text-align: center;
    cursor: pointer;
}

.file-input-sec .f-input::placeholder {
    text-align: center;
}

.editorBannerSec {
    height: 510px;
}

.editorBannerSec .billboard-cards .card-img {
    border-radius: 12px;
    overflow: hidden;
}

.editorBannerSec .billboard-cards .card-img img {
    width: 100%;
}

.editorBannerSec .myTeamBanner .card-body {
    margin: 20px 0;
}

.editorBannerSec .myTeamBanner .card-body h5 {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
}

.editorBannerSec .myTeamBanner .card-body p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
}

.card-heading {
    font: normal normal normal 16px/21px Francois One;
    letter-spacing: 0px;
}

.current-banner {
    background: #CB8B15;
    opacity: 1;
    margin-bottom: 20px;
}

/* -------- edit section --------- */
.editable-sec {
    background-color: #bdbdbd;
    color: #131313;
    padding: 10px 15px;
    border: 1px solid #000;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Century-Bold' !important;
}

.editnewscard-img {
    max-width: 350px;
    height: 200px;
    margin: 20px auto 0;
    position: relative;
    border-radius: 13px;
    overflow: hidden;
}

.editnewscard-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}




.lista-content .myTeamBanner {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.lista-content .myTeamBanner {
    padding: 4px 12px;
    margin-bottom: 20px;
    align-items: center;
}

.lista-content .myTeamBanner .card-img img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.lista-content .myTeamBanner .card-img {
    width: auto;
    height: auto;
}

.lista-content .lista-titles {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    border-top: 1px solid #fff;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 9;
    background: #0c0c0c;
}

.lista-content .lista-titles>div {
    padding: 13px 30px;
    border-left: 1px solid #fff;
    text-align: center;
}

.lista-content .lista-name {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 40%;
    padding: 0 30px;
    justify-content: center;
}

.lista-creation {
    width: 25%;
    padding: 0 30px;
}

.lista-modification {
    width: 25%;
    padding: 0 30px;
}

.lista-right {
    width: 10%;
    padding: 0 30px;
}

.lista-content .billboard-cards h3 {
    text-transform: uppercase;
    font-weight: 500;
}


.lista-content h3,
.lista-content p {
    margin: 0;
}

.lista-content .lista-name .card-heading {
    width: calc(100% - 70px);
    text-align: center;
}

.lista-content .myTeamBanner .card-img video {
    width: 70px;
    height: 70px;
}

.dropdown-menu.show {
    height: auto;
}

.myTeamBanner video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.swiper-navigate .swiper-button-next:after,
.swiper-navigate .swiper-button-prev:after {
    margin-top: 170px;
}