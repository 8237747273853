.footer{
    margin-bottom: 40px;
    padding: 20px 0;
}
.footer-flag{
    position: relative;
}
.footer-sec .text-sec{
    font-size: 16px;
    line-height: 20px;
    margin-left: 40px;
}
.flag-img {
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    max-width: 60px;
    top: 50%;
}
.flag-img img{
    width: 100%;
    height: 100%;
}
.flag-colors .grey-color{
    display: block;
    height: 10px;
    width: 100%;
    background: #8297A0 0% 0% no-repeat padding-box;
}
.flag-colors .white-color{
    display: block;
    height: 10px;
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
}