.todas-card {
    background: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 50px;
    align-items: center;
    padding: 5px 25px 5px 5px;
    margin-bottom: 30px;
}

.todas-card .left-sec {
    display: flex;
}

.todas-card .left-sec .profile-img {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    overflow: hidden;
}

.todas-card .left-sec .profile-content {
    margin-left: 20px;
    padding: 8px 0;
}

.todas-card .left-sec .profile-content h5 {
    font-size: 19px;
    line-height: 22px;
    margin: 0 0 3px 0;
    color: #131313;
    font-family: 'Century-BoldItalic';
}

.todas-card .left-sec .profile-content p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: #131313;
    font-family: 'Century-Italic';
}

.digital-heading {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    align-items: center;
    margin-bottom: 30px;
}

.digital-heading h3 {
    font-size: 21px;
    line-height: 25px;
    text-transform: uppercase;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
    color: #fff;
    font-size: 19px;
    line-height: 26px;
    text-transform: uppercase;
    border: 0;

}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
    background: transparent;
    border-bottom: 3px solid #EBA51A;
}

.tabs-cards-sec .nav-tabs {
    border: 0;
    margin-bottom: 50px;
}

.back-sec {
    cursor: pointer;
}

.gems {
    display: flex;
    align-items: center;
    color: #fff;
}

.membersias-sec {
    margin: 0 auto;
    max-width: 240px;
    width: 100%;

}

.gems>img {
    padding-right: 15px;
}

.available-membership {
    margin-top: 100px;
    max-width: 100%;
    background-color: #131313;
    color: #FFF;
}

.available-membership>h6 {
    text-align: center;
    padding: 10px 15px;
    border: 1px solid #FEF6F2;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0;
}

.available-body {
    border: 1px solid #FEF6F2;
    padding: 20px 25px;
    list-style-type: none;
    margin-bottom: 0;
}

.available-body>li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.available-body>li>span {
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
}

.available-body>li>span>img {
    width: 16px;
    margin-right: 11px;
}

.available-body>li:not(:last-child) {
    margin-bottom: 30px;
}

.digital-leader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    width: 100%;
}

.digital-leader>h6 {
    font-style: italic;
    color: #fff;
}

.digital-leader>:nth-child(1),
.digital-leader>:last-child {
    width: 20%;
    text-align: right;
    font-style: italic;
    align-self: flex-start;
    padding-top: 50px;
}

.digital-leader>div:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.digital-leader .center-dl {
    width: 80%;
}

.center-head {
    text-align: center;
}

.center-head>img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 50%;
}

.center-head>h3 {
    font-size: 19px;
    line-height: 20px;
    margin-top: 13px;
}

.personal-info {
    max-width: 350px;
    margin: 70px auto 0;
}

.personal-info>h4 {
    font-size: 19px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 30px;
    text-align: center;
}

.personal-data {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #FEF6F2;
    margin-bottom: 30px;
    padding-bottom: 15px;
}

.personal-data>span {
    width: 50%;
}

.personal-data>span {
    font-size: 11px;
    font-style: italic;
}

.personal-data>span:nth-child(2) {
    color: #FEF6F2;
}

.available-badge {
    text-align: center;
    color: #fff;
}

.available-badge>h4 {
    font-size: 19px;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 17px;
}

.available-badge h6 {
    font-size: 9px;
    line-height: 15px;
    color: #EBA51A;
    text-transform: uppercase;
}

.pr-hier {
    margin-bottom: 35px;
}

.available-badge .row>div {
    margin-bottom: 30px;
    padding: 0 5px;
    position: relative;
}

.first-row {
    position: relative;
}

.row.first-row>div {
    margin-bottom: 45px;
}

.row.first-row>div:before {
    content: '';
    position: absolute;
    bottom: -27px;
    left: -1px;
    right: 0;
    background: #fff;
    width: 1px;
    height: 18px;
    margin: 0 auto;
}

.sec-row {
    position: relative;
}

.row.sec-row:before {
    content: '';
    position: absolute;
    top: -18px;
    left: 0;
    right: 0;
    background: #fff;
    width: calc(100% - 33.33%);
    height: 1px;
    margin: 0 auto;
}

.available-badge .row.sec-row>div:before {
    content: '';
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1px;
    height: 18px;
    background: #fff;
}

.available-badge .row.sec-row>div:nth-child(1):before,
.available-badge .row.sec-row>div:nth-child(2):before,
.available-badge .row.sec-row>div:nth-child(3):before {
    top: -17px;
}

.disable {
    opacity: 0.6;
}


.digi-profile-sec {
    max-width: 200px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.digi-profile-sec .gems {
    justify-content: center;
    padding: 20px 0;
}

.gems-movements-sec .gems-movements {
    text-align: center;
    margin: 5px 0 20px;
}

.gems-movements-sec {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
}

.gems-movements-sec .gems-card {
    display: flex;
    margin: 0 0 10px 0;
    justify-content: space-between;
}

.gems-movements-sec .gems-card .left-side {
    display: flex;
}

.gems-movements-sec .gems-card .left-side .img-sec {
    width: 40px;
    height: 40px;
}

.gems-movements-sec .gems-card .left-side img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.gems-movements-sec .gems-card .details-sec {
    margin-left: 20px;
}

.gems-movements-sec .gems-card .details-sec h5 {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 3px 0;
}

.gems-movements-sec .gems-card .details-sec p {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}

.gems-movements-sec .gems-card .increasing {
    font-size: 19px;
    line-height: 20px;
    color: #1DBE2E;
    margin-bottom: 5px;
}

.decreasing {
    font-size: 19px;
    line-height: 20px;
    color: #FF0707;
    margin-bottom: 5px;
}

.gems-movements-sec .gems-card .sub-hed {
    font-size: 12px;
    color: #FEF6F2;
    opacity: 0.6;
    text-align: center;
}

.gems-card-list {
    height: 460px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 15px;
}

/* ----------------- */
.bottom-send-sec.d-flex.my-3.px-0 {
    margin-right: 29px;
    width: inherit;
}

.digital-manager-page .msger-chat {
    padding: 15px;
    height: auto;
    max-height: unset;
}


.digital-manager-page .msger {
    max-width: 350px;
    margin-left: auto;
    height: 320px;
}

.digital-manager-page .msger .input-form-wrap form input[type=text] {
    padding: 7px 30px;
    font-size: 12px;
    line-height: 2;
}

/* .chatPage-sec{
    visibility: hidden;
}
.showbox{
    visibility:visible;
} */

.chatPage-sec .msger {
    display: none;
}

.showbox .msger {
    display: block;
}

.showbox .msger {
    display: flex;
}

span.lock-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.h-530>div {
    height: 530px;
    overflow: auto;
}

.parent .profile-img>img {
    width: 62px;
    height: 62px;
    padding: 0 !important;
    overflow: auto;
    object-fit: cover;
}

.parent .profile-img {
    padding: 0;
}


.lock-icon-wrapper>img {
    height: 60px;
    width: 60px;
    object-fit: contain;
}

.digital-manager-chat {
    display: block;
    margin: 0 auto;
    max-width: 240px;
    text-align: center;
    padding: 0;
}

.digital-manager-chat>button {
    max-width: 100%;
    margin: 35px 0 30px 0 !important;
}

.chatPage-sec.chatBox-toggle.showbox .msg-img {
    margin-right: unset;
    bottom: 0;
    top: auto;
}

.chatPage-sec.chatBox-toggle.showbox .right-msg .msg-item {
    padding-right: 20px;
}

.center-head .block-icon {
    width: 50px;
    position: absolute;
    height: 50px;
    margin: 35px;
    /* right: 10px;
    top: 10px; */
}

.audio-recorder {
    margin-left: auto;
    margin-top: -35px;
    z-index: 999;
    position: relative;
}

.msger .other-wrap form input[type=text] {
    width: 80% !important;
    background-color: #131313;
    padding: 13px 13px 13px 50px;
    border: none;
    box-sizing: border-box;
    color: white;
}

.right-50 {
    right: 50px !important;
}

.audio-msg audio {
    width: 100% !important;
    background: none !important;
}

.audio-recorder {
    /* background-color: unset; */
    /* box-shadow: none; */
    /* border-radius: 0px; */
    /* box-sizing: border-box; */
    /* color: #000; */
    /* width: 40px; */
    /* display: flex; */
    /* align-items: center; */
    /* transition: all .2s ease-in; */
    /* -webkit-tap-highlight-color: transparent; */
}

/* .audio-recorder-mic {
    filter: invert(100%);
    height: 20px;
    padding: 8px;
}  */