.addMultimedia-sec-inner {
    padding: 50px 0 30px;
}

.multimediaImg-sec {
    margin-top: 50px;
}

.multimediaImg-sec .multimediaImg-card {
    max-width: 350px;
    width: 100;
    margin: 0 auto;
    text-align: center;
}

.multimediaImg-sec .multimediaImg-card p {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
}

.mulImg-btn-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.common-form-control.img-video-form-control {
    position: relative;
}

.common-form-control.img-video-form-control .fileUpload.btn.btn--browse {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.preview-imgCon {
    max-width: 300px;
    width: 100%;
}

/* .preview-imgCon img,
.preview-imgCon video {
    width: 100%;
    height: 100%;
    object-fit: contain;
} */
 
.preview-imgCon img,
.preview-imgCon video {
    width: 100%;
    height: 100px;
    object-fit: cover;
    padding: 5px;
}

.legend-img:active {
    transform: scale(0.9);
}

.selected {
    border: 2px solid white;
}

.historical-legends:has(.mm-ack) {
    display: block;
    text-align: center;
}

.preview-multimedia-large video {
    width: 500px;
}

.legend-media-manage img {
    width: 500px;
    height: 500px;
    object-fit: contain;
}

.preview-imgSec {
    text-align: center;
}

.hidden-thumbnail-preview {
    display: none;
}