.experience-card-sec{
    margin-top: 50px;
}
.experience-card-sec .exp-card{
    max-width: 350px;
    width: 100%;
    margin: 0 auto 20px;
}
.exp-card .card-heading h3{
    font-size: 16px;
}
.experience-sec{
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.exp-btn-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 10px;
    gap: 30px;
}
.editExp-content{
    padding: 50px 20px 30px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.edit-exp-card{
    max-width: 350px;
    width: 100%;
    margin: 0 auto 30px;
    
}
.edit-exp-card .card-heading h3{
    font-size: 20px;
    text-align: center;
}
.edit-exp-data .desc{
    font-size: 12px;
    line-height: 16px;
    font-family: 'Century-Italic';
    margin-bottom: 5px;
}
.req-sec {
    margin-top: 30px;
}
.req-sec .req-heading{
    font-size: 12px;
    line-height: 16px;
    font-family: 'Century-Italic';
    margin-bottom: 25px;
    text-transform: uppercase;
}
.req-sec .req-list{
    padding: 0;
}
.req-sec .req-list li p{
    font-size: 12px;
    line-height: 16px;
    font-family: 'Century-Italic';
    position: relative;
    padding-left: 20px;
}
.req-sec .req-list li p::before{
    content: "*";
    position: absolute;
    left: 0px;
    font-size: 20px;
    top: 5px;
}
.common-dash-padding{ 
    padding: 0px 0 10px;
}
/* exprience front box */
.news-card{
    max-width: 350px;
    width: 100%;
    margin: 0 auto 40px;
}
.news-card .news-body{
    max-width: 100%;
    height: 180px;
    width: 100%;
    overflow: hidden;
    border-radius: 13px;
    /* margin: 30px auto 0; */
}
.news-card .news-body img, .news-card .news-body video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}



/* upload btn sec */
.img-edit-icon {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    right: 10px;
    bottom:10px;
}
.img-edit-icon input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.editable-sec{
    background-color: #bdbdbd;
    color: #131313;
    padding: 10px 10px;
    border: 1px solid #000;
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Century-Bold' !important;
}
/* file input */

.common-form-control.img-video-form-control .fileUpload.btn.btn--browse {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}


.file-input-sec .fileUpload {
    position: relative;
    overflow: hidden;
     cursor: pointer;
}
.file-input-sec .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0;
    left: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;   
    height: 100%;
    width: 100%;
    z-index: 1;
    max-width: 100%;
}

.file-input-sec .btn--browse{  
    border: 1px solid #3a3838;
    outline: none;
    cursor: pointer;
}
.file-input-sec .btn--browse:hover{
    border: 1px solid #3a3838;
    cursor: pointer;
}

.file-input-sec .f-input{	
	width: 100%;
	max-width: 400px;
	padding: 10px 14px;
    text-align: center;
     cursor: pointer;
}
.file-input-sec .f-input::placeholder{
    text-align: center;
}

.req-list li select {
    padding: 10px 15px;
    background: #131313;
    color: #CB8B15;
    border: 1px solid #FEF6F2;
    border-radius: 30px;
}

.experience-video video{
    width: 100%;
}