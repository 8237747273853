.newsAddContent .auth-relative>span {
    position: relative !important;
}
.equipo_name{
    font-size: 16px;
    line-height: normal !important;
}
.link-institute-shield img{
    height: 55px;
}
.institutional-page .common-form-control label{
    font-size: 18px;
}
.dropdown-btn.btn.show,
.categories-dropdown .dropdown-btn,
.categories-dropdown .dropdown-btn:hover{
    background: transparent;
}
.history-heading-lable {
    align-items: baseline;
}
.dropdown-menu.show {
    max-width: 60%;
}
.tier-drop-down.categories-dropdown .dropdown-menu{
    height: 450px !important;
    background-color: #00000080;
}