.sidebar {
    width: 0;
    position: fixed;
    top: 70px;
    left: -330px;
    min-height: calc(100% - 75px);
    box-shadow: 2px 2px 3px #000000;
    border-right: 0.30px solid #1a1a1a;
    border-bottom: 0.30px solid #1a1a1a;
    border-radius: 0px 0px 52px 0;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    overflow-x: hidden;
    transition: 1s;
    z-index: 99;
}

.sidebar.open {
    width: 330px;
    left: 0;
    top: 70px;
    transition: 1s;
    overflow-y: scroll;
    height: 500px;
}

.menu-icons-btn {
    background-color: transparent;
    border: 0;
    color: #fff;
}

.menu-icons-btn>svg {
    width: 30px;
    height: 30px;
}

.sidebar-link-sec {
    padding: 30px 0px;
    width: 361px;
}

.sidebar-link-sec li .sidebarLink {
    padding: 10px 25px;
    display: flex;
    align-items: center;
}

.sidebar-link-sec li .sidebarLink:hover {
    opacity: 0.5;
}

.sidebar-link-sec li .sidebarLink img {
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.sidebar-link-sec li .sidebarLink span {
    text-align: left;
    font-size: 14px;
    line-height: 28px;
    font-family: Francois One;

    letter-spacing: 0px;
    color: #FEF6F2;
    text-transform: uppercase;
}