.common-input-content p {
    font-size: 16px;
    font-style: italic;
    line-height: 1.3;
}

.common-input-content p strong {
    font-style: normal;
}

.institutional-page .common-form-control label {
    position: sticky;
    top: 0;
    width: 100%;
    text-align: center;
}

.institutional-page .common-form-control {
    position: relative;
}

.h-140 {
    height: 140px;
    overflow: hidden auto;
}

.desc-input input {
    width: 100%;
    display: block;
    max-width: 100%;
}

.desc-input input::placeholder {
    text-align: left;
}

.modal-header .btn-close {
    background: url('../../../public/img/close.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: calc(50% + 10px);
    left: 27px;
    transform: translateY(-50%);
    padding: 0 !important;
    opacity: 1;
}

.common-popup .modal-header {
    border-bottom: 0;
    position: relative;
}

.institutional-popup .modal-header button {
    margin: 0;
}

.common-popup .modal-dialog {
    max-width: unset;
    width: 100%;
    justify-content: center;
}

.institutional-popup .modal-content {
    max-width: 745px;
    width: 100%;
    height: 100%;
}

.common-popup .modal-content {
    box-shadow: 2px 2px 3px #000000;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    transition: 1s;
    z-index: 99;
    background: transparent;
    border: 1px solid #FEF6F2;
    border-radius: 15px;

}

.common-popup .modal-title {
    width: 100%;
    text-align: center;
    font-size: 21px;
    line-height: 22px;
}

.common-popup .modal-body {
    padding: 27px;
}

.yellow-btn {
    max-width: 230px;
    width: 100%;
    border-radius: 13px;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 20px;
    border: 0;
    color: #fff;
    font-weight: 900;
    margin-bottom: 15px;
    background-color: #CB8B15;
    text-transform: uppercase;
}


.common-popup .modal-footer {
    width: 100%;
}

.common-popup .news-btn-sec {
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    gap: 40px;
}

.common-popup .news-btn-sec button {
    max-width: max-content;
    padding: 6px 15px;
}

.slider-details label {
    font-size: 14px;
    display: block;
    margin-bottom: 15px;
}

.slider-details textarea {
    width: 100%;
    height: 175px;
    padding: 20px;
    border-radius: 5px;
    background: #FEF6F2 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 1px solid #707070;
    resize: none;
    color: #131313;
    font-size: 13px;
    font-style: italic;
    margin-bottom: 10px;
}

.institutional-popup .modal-header {
    padding: 24px;
    margin-bottom: 12px;
}

.common-popup .modal-footer button {
    margin-bottom: 24px;
}


.institutional-popup .modal-body {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

}

.inst-popup {
    padding: 27px 0;
}

.slider-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.swiper-pagination {
    position: absolute;
    top: 30px !important;
    height: 20px;
}

.swiper-pagination-bullet {
    background: #fff !important;
    width: 13px !important;
    height: 13px !important;
}

.swiper-pagination-bullet-active {
    background: #CB8B15 !important;
}

.page-counter {
    flex: 0 100%;
    font-size: 16px;
    text-transform: uppercase;
}


.proposal-popup .modal-content {
    max-width: 915px;
}


.errSpn {
    font-size: small;
}


/* css for text editor */

.custom-text-editor button img {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.custom-text-editor .toolbar button {
    background: unset;
    border: unset;
}

.toolbar .text-align-buttons {
    display: flex;
    gap: 10px;
}

.toolbar {
    display: flex;
    gap: 10px;
    background: #FEF6F2;
    padding: 10px 10px 15px;
    border-radius: 16px 16px 0 0;
    max-width: 180px;
    margin: 0 auto -8px;
    z-index: 9999;
    position: relative;
}


.editor {
    border: 1px solid #ccc;
    padding: 20px;
    min-height: 500px;
    outline: none;
    line-height: 1.2;
    font-size: 13px;
    background: #FEF6F2;
    color: #000;
    border-radius: 5px;
    border: 1px solid #707070;
    box-shadow: inset 0px 3px 6px #00000029;
    line-height: 18px;
}

.text-align-buttons button {
    margin-right: 5px;
}

.output {
    margin-top: 10px;
}

.output div {
    margin-top: 5px;
}

.common-input-content {
    max-height: 125px;
    overflow: auto;
}

.common-input-content p span,
.common-input-content p strong {
    font-family: 'Century-BoldItalic';
}

.link-div p {
    font-size: 16px;
}


.authority_name {
    height: 50px !important;
    padding: 10px 20px !important;
}

.slider-details label>select {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border-radius: 5px;
    background: #FEF6F2 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border: 1px solid #707070;
    resize: none;
    color: #131313;
    font-size: 13px;
}

.mt-50 {
    margin-top: 50px;
}

.newsAddContent .auth-relative>span {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 18px;
}


.inst-popup .swiper-button-prev::after {
    width: 18px;
    height: 18px;
    font-size: 20px;
    color: #fff;

}



.inst-popup .swiper-button-next::after {
    width: 18px;
    height: 18px;
    font-size: 20px;
    color: #fff;
}


.inst-popup .swiper-button-next {
    right: 0;
}

.inst-popup .swiper-button-prev {
    left: 0;
}


.inst-popup .swiper {
    padding: 0 30px;
}

.authority_content .common-form-control {
    height: auto;
    max-height: 180px;
}

.link-institute-shield img{
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.link-description-manage input{
    max-width: 460px;
    word-wrap: break-word;
    
}