.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.user-profile-sec {
    display: flex;
    align-items: center;
}
/* .logo-sec {
    position: relative;
    left: 100px;
} */

.notification {
    margin-right: 30px;
    position: relative;
}
.notification .red-dot{
    position: absolute;
    right: 2px;
    top: 2px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    display: inline-block;
}
.user-profile{
    margin-right: 30px;
    position: relative;
}
.user-profile .user-name{
    font-size: 15px;
    line-height: 20px;
    margin-left: 6px;
}
.select-lang select {
    background: #000;
    border: 0.5px solid #FEF6F2;
    border-radius: 10px;
    color: #FEF6F2;
    padding: 5px;
    font-size: 13px;
}



.navbar-container {
    flex-wrap: wrap;
}

.navbar-container .menu-bar {
    flex: 0 33.33%;
}

.navbar-container .logo-sec {
    flex: 0 33.33%;
    text-align: center;
}

.navbar-container .user-profile-sec {
    flex: 0 33.33%;
    justify-content: end;
}

img.user-profilePic {
    width: 35px;
    border-radius: 50%;
}